@import "../variables";

.modal-message {
    .modal-content {
        color: #212529;
        border-radius: $border-radius;
        padding: 20px;
        .modal-header {
            .modal-title {
                color: #dd0000;
            }
        }
        .btn-primary {
            border-color: #23346C;
            background-color: #23346C;
        }
    }
    .modal-footer {
        padding-bottom: 0px;
    }
}