.unavailable-button-wrapper {
    .modal-dialog {
        top: 20%;
        .modal-content {
            padding: 10px;
        }
    }
}

.button{
    border: none;
    background: none;
    outline: none;
    border-radius: 5px;
    text-decoration: none;

    &:focus{
        outline: none;
    }
}

.button.button-primary{
    background-color: $btnPrimaryColor;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    padding: 15px;
    &:hover{
        background-color: $btnPrimaryColorHover;
        color: $white;
    }
}

.button.button-tertiary{
    background-color: #454545;;
    color: $white;
    padding: 15px;
    border: 1px solid #454545;
    &:hover{
        background-color: transparent;
        color: #000;
    }
}

.button.button-secondary{
    background-color: $brandColorDarker2;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    padding: 15px;
    &:hover{
        background-color: $brandColorDarker4;
        color: $white;
    }
}