.checkbox{
    padding-left: 20px;
    width: 100%;
    cursor: pointer;
    position: relative;
    z-index: 3;

    input[type=checkbox]{
        display: none;
    }

    input[type=checkbox]:checked ~ .check::before{
        content: '';
        position: absolute;
        top: 20%;
        left: 30%;
        transform: translate(-15%, 0%);
        width: 60%;
        height: 60%;
        background-color: $brandColorDarker1;
    }

    .check{
        height: 20px;
        width: 20px;
        position: absolute;
        top: 50%;
        left: -1px;
        transform: translate(0, -50%);
        background: white;
        border: 1px solid $brandColorDarker1;
        border-radius: 3px;
    }
}
