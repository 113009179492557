
* {
    font-family: "Open Sans";
}

$breakpoint-name: "sm";
$breakpoint-name: "sm" !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    font-family: $icomoon-font-family;
    content: $icon-chevron-right;
    border: 0px;
    margin: 0px;
    right: 1rem;
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item+.nav-item {
            margin-left: 0;
        }

        .navbar-nav .nav-link {
            padding: 0.8rem 0.5rem;
            white-space: nowrap;
        }
    }
}

.navbar-expand-md .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
    justify-content: end;
}

@media (min-width: 768px) {

    .nav-item .nav-link:hover,
    .nav-item .nav-link:focus,
    .nav-item.show .nav-link {
        background-color: $white;
        color: $menu-link;
    }


    .navbar.bg-inverse .navbar-nav {
        padding: 0px;
    }
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item>.nav-link {
        color: $white;
    }
}

.main-menu.menu-toggleable-left {
    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
    }
    z-index: 2147483648; //MAX Z-INDEX
}

.menu-toggleable-left {
    .close-menu {
        padding: 15px;
        background-color: #ffffff;
        border-bottom: 1px solid $grey3;
        flex: 0 0 100%;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    .close-bar {
        background: #ffffff;
        border: none;
        padding: 10px !important;

        .back img {
            transform: rotate(180deg);
        }
    }

    .menu-group {
        display: flex;
        flex: 0 0 100%;
        width: 100%;
    }

    li>.close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }

    &.in {
        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
            margin-right: 1.25em;
            /* 20/16 */
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item + .nav-item {
                border-top: 0;
            }
        }


        @include media-breakpoint-down($breakpoint-name) {
            .dropdown, .nav-item-all {
                display: flex;
                position: static;
                justify-content: space-between;
                padding-right: 15px;
                font-weight: 600;
                align-items: center;
            }

            .dropdown-toggle {
                //padding-left: 1rem;

                & span{
                    float: right;
                    padding-top: 4px;
                }

                &::after {
                    @include caret-right();
                }
            }

            .nav-item .nav-link {
                //padding-left: 25px;
                color: #000;
                font-size: 13px;
                flex: 1;
                padding: 0.75rem 0 0.75rem 25px;
                font-weight: 400;
                height: 48px;
                margin: 0px !important;
            }

            .last-link{
                font-weight: 500 !important;
            }

            .show>.dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100vh;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
                overflow: scroll;
            }
        }
    }

    .close-menu.clearfix.menu-group-mobile {
        height: 100vh;
        border: 0;
    }

    .menu-group-mobile {
        .menu-group {
            height: 100%;
            .navbar-nav {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-wrap: nowrap;
            }
            .dropdown-menu {
                height: 100svh;
                background:#ffffff;
                overflow: auto !important;
            }
        }
    }
}

.modal-background {
    z-index: 1001;

    @media (min-width: 768px) {
        .modal-background {
            display: none !important;
        }
    }
}

.multilevel-dropdown, body {
    .dropdown-menu {
        top: 90%;
        border: 0;
        border-radius: 0;

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }

    .dropdown-item.dropdown>.dropdown-toggle::after {
        @include caret-right();
    }

    .dropdown-menu>.dropdown>.dropdown-menu {
        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar>.close-menu>.back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
        padding: 0 0 0 1em;

        .dropdown-link {
            display: block;
            font-size: 13px;
            line-height: 1.5;
            padding: .75rem 1rem;
        }

        +.dropdown-item {
            border-top: 1px solid $close-menu-bg;
        }

        &.top-category {
            font-weight: bold;

            &:active {
                background-color: transparent;
            }

            >.nav-link {
                padding-left: 1rem;
                font-size: 15px;
            }
        }
    }
}

.leftSideItems {
    a {
        font-family: "Open Sans" !important;
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: black !important;
    }
}

.RightSideItems {
    a {
        font-family: "Open Sans" !important;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: black !important;
    }
}

.banner-color,
.main-menu {
    background-color: $brandColorGray1 !important;
}

.leftSideItems {
    margin: 0px 10px 0px 0px;
}

@media (min-width: 768px) {
    .nav-item:hover {
        background-color: $brandColorGray1 !important;
    }

    .nav-item:focus {
        background-color: $brandColorGray1 !important;
    }
}

.nav-link.threeLinesIcon,
.nav-link.locationIcon,
.nav-link.arrowDownIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.nav-link.locationIcon {
    gap: 20px !important;
    font-size: 12px;
    color: #002b70 !important;
    padding: 0;
}

.threeLinesIcon::before {
    content: url(../images/threeLinesIcon.svg);
}

// .arrowDownIcon::after {
//     content: url(../images/arrowDownIcon.svg);
//     translate: -7px 1px;
// }

.minicart .popover {
    position: fixed;
    top: 0;
    min-width: 331px;
    max-width: 331px;
    z-index: 2147483648;
}

@each $size in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            max-width: 100%;

            &.in {
                left: 0;
                width: 324px;
                //height: fit-content;
                font-family: "Open Sans" !important;
            }
        }

        .menu-toggleable-left .close-menu {
            padding: 0;
        }

        .categories-mobile {
            flex-direction: column;
            background-color: #fff;
            color: #000;
            font-weight: 600;
            border-top: 1px solid #d9d9d9;
        }

        .header-options-mobile {
            flex-direction: column;
            background-color: #fff;

            .nav-item {
                height: 50px;
                display: flex;
                align-items: center;
            }

            .icon{
                font-size: 20px;
                color: #0e2a6c;
            }
        }

        .login-button-mobile {
            background-color: #0ed178;
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            border-radius: 5px;
            border: 0;
            width: 130px;
            height: 40px;
        }

        .login-button-mobile:focus,
        .register-button-mobile:focus {
            outline: none;
        }

        .register-button-mobile {
            background-color: #23346c;
            color: #fff;
            font-size: 12px;
            font-weight: 400;
            border-radius: 5px;
            border: 0;
            width: 130px;
            height: 40px;
        }

        .buttons-menu-mobile {
            display: flex;
            gap: 6px;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .icon-message-menu {
            display: flex;
            gap: 10px;
            flex-direction: row;
            align-items: center;
            i.icon-profile {
                font-size: 24px;
                color: #002b70;
            }
            .user-message{
                color: $brandColorDarker1;
            } 
        }

        .user-account a{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            text-decoration: none;

            .user-message-mobile{
                flex-direction: row;
                align-items: center;
            }

            &:hover{
                text-decoration: none;
            }
        }

        .container-header-mobile {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px
        }

        .container-header-mobile-logged {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
        }

        .menu-header-mobile {
            display: flex;
            flex-direction: column;
        }

        @media (min-width: 768px) {
            .menu-header-mobile {
                display: none;
            }
        }

        .header-mobile-login {
            width: 100%;
            padding: 0px 25px;
            border-bottom: 1px solid #e1e1e1;

            @media screen and (min-width: 768px) and (max-width: 991px) {
                display: none;
            }
        }

        .user-message-mobile {
            color: #002b70;
            font-size: 12px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            /* autoprefixer: off */
            overflow: hidden;
            @media (max-width: 768px) {
                font-size: 16px;
            }
            width: 102px;
        }

        .value-content-menu {
            display: flex;
            gap: 6px;

            span {
                font-size: 12px;
                color: #505050;
            }
        }

        .myAccount-menu-header {
            display: flex;
            flex-direction: column;
            padding-inline-start: 0;
            margin-bottom: 0;
            border-top: 1px solid #d9d9d9;

            li {
                list-style-type: none;
                display: flex;
                align-items: center;
                height: 48px;

                a {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #4a4a4a !important;
                    text-decoration: none;
                    padding-left: 25px;

                    &.option-data {
                        &:before {
                            content: url(../../images/myAccount/iconMyData.svg);
                            filter: grayscale(100%) brightness(142%);
                            margin-top: 6px;
                        }
                    }

                    &.option-password {
                        &:before {
                            content: url(../../images/myAccount/iconMyPassword.svg);
                            filter: grayscale(100%) brightness(142%);
                            margin-top: 6px;
                        }
                    }

                    &.option-orders {
                        &:before {
                            content: url(../../images/myAccount/iconMyOrder.svg);
                            filter: grayscale(100%) brightness(142%);
                            margin-top: 6px;
                        }
                    }

                    &.option-address {
                        &:before {
                            content: url(../../images/myAccount/iconMyAddress.svg);
                            filter: grayscale(100%) brightness(142%);
                            margin-top: 6px;
                        }
                    }

                    &.option-myList {
                        &:before {
                            content: url(../../images/myAccount/iconMyList.svg);
                            filter: grayscale(100%) brightness(142%);
                            margin-top: 6px;
                        }
                    }

                    &.option-management {
                        &:before {
                            content: url(../../images/myAccount/iconManagement.svg);
                            filter: grayscale(100%) brightness(142%);
                            margin-top: 6px;
                        }
                    }

                    &.option-logout {
                        &:before {
                            content: url(../../images/myAccount/iconLogout.svg);
                            filter: grayscale(100%) brightness(142%);
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
    }
}

.leftSideItems, body {
    .nav-link {
        border-radius: 5px 5px 0px 0px;
    }

    .dropdown-menu {
        border-radius: 0px 5px 5px 5px;
        padding: 0px ;
        box-shadow: none;
        height: auto;

        div,
        .dropdown-item {
            border: none;
            border-top: none;
            padding: 0;

            &:hover {
                background-color: transparent;
                text-decoration: none;
            }

            a {
                color: #000;
                font-family: "Open Sans";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                //line-height: 25px;
                line-height: 18px;

                &:hover {
                    text-decoration: none;
                    font-weight: 600;
                }
            }

            // .dropdown-menu {
            //     padding: 10px 28px 10px 49px;
            // }
        }
    }
}

.iconMap::before {
    content: url(../images/locationIcon.svg);
    translate: 0;
    position: relative;
    display: inline-block;
    top: 2px;
    width: 18px;
    margin-right: 7px;
}

.locationHeader {
    line-height: 1.3;
    width: 175px;
}

@media (max-width: 991px) {
    .locationHeader {
        width: auto;
    }
   .locationAddress {
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-wrap: nowrap;
    }

    .iconMap:before {
        transform: scale(0.9);
    }
}

.scroll-up-indicator,
.scroll-down-indicator {
    text-align: center;
    padding: 5px;
    cursor: pointer;
    display: none;
}

.scroll-up-indicator {
    padding: 10px 0;
    z-index: 5;
    position: sticky;
    top: 0;
    transform: rotate(180deg);
    background:  #fff !important;
    padding-left: 28px !important;
    display: none;
}

.scroll-down-indicator {
    padding: 10px 0;
    z-index: 5;
    position: sticky;
    bottom: 0;
    background:  #fff !important;
    padding-right: 28px !important;
    display: none;
}

.navbar-nav {
    width: 100%;
    .nav-item {
        position: relative;

        // Submenu styles
        
    }
}

.dropdown-menu {
    max-height: calc(-350px + 100vh);
    overflow: hidden auto;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    scroll-snap-type: y mandatory;


    .dropdown-submenu {
        position: relative;

        .dropdown-menu {
            left: 100%;
            top: 0;
            max-height: none; // Reset max-height for sub-submenus
        }
    }
}
