.carousel-control-next-icon {
    background-image: url(../images/nextIcon.svg);
}

.carousel-control-prev-icon{
    background-image: url(../images/prevIcon.svg);
}

.modal-backdrop.in{
    display: none !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    width: 41px;
    height: 41px;
    opacity: 1;
}

.form-control.is-invalid {
    background-image: none !important;
    &:focus {
        border-color:#c00 !important ;
        box-shadow: none !important;
    }
}

.form-control.is-invalid#invalid-code{
    display: block;
    color: red;
}

#invalid-code{
    display: none;
}

.container .breadcrumb {
    padding: 0;
    margin: 21px 0px 27px 0;
    border-bottom: none;

    a {
        font-size: 12px;
        font-weight: 400;

        &.lastBreadcrumb {
            color: $black !important;
        }

        &.currentBreadcrumb {
            color: #A0A0A0 !important;
        }
    }

    .breadcrumb-item+.breadcrumb-item:before {
        color: #A0A0A0;
        content: '>';
        vertical-align: middle;
        padding-right: 0;
    }
}

.btn-outline-success:hover{
    color: #fff !important;
    background-color: #02754B !important;
    border-color: #02754B !important;
    font-weight: 700;
}

.btn-outline-success {
    border-color: #02754B !important;
    color: #02754B !important;
    font-weight: 700;
}

.modal {
    z-index: 1000002 !important;
}

.breadcrumb-item {
    color: #444;
    font-weight: 600;
}