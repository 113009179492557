@import "../variables";
@import "bootstrap/scss/variables";

.stepMenuContainer {
    padding-top: 8px;
    gap: 24px;
    @media (min-width: 992px) {
        gap: 40px;
        background: #F6F6F6;
    }
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $brandColorGray1;
}
.stepMenu, .stepMenuContainer {
    display: flex;
    justify-content: center;
}

.stepMenu {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    column-gap: 8px;
    span {
        color: #989898;
        width: 36px;
        height: 36px;
        background-color: $brandColorGray1;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        font-weight: bold;
    }
    p {
        color: #989898;
        text-transform: uppercase;
        margin-bottom: 8px;
        @media(max-width:'768px') {
            display: none;
        }
        @media (min-width: 992px) {
            margin-bottom: 12px !important;
        }
    }
    &.active {
        border-bottom: 3px solid $brandColorDarker4;
        span {
            color: #ffffff;
            background-color: $brandColorDarker4;
        }
        p {
            color: $brandColorDarker4;
            font-weight: bold;
        }
    }
    &.current {
        p {
            display: unset;
        }
    }
}

.handle-step__content {
    cursor: pointer;
    max-width: 1319px;
    margin-right: auto;
    margin-left: auto;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 0 12px;
    span {
        text-decoration: underline;
    }
}