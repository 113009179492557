@import "../variables";

.overflowHidden {
    overflow-y: hidden;
}

body header{
    position: relative;
    z-index: 1002;
}

body.overflowHidden .searchSuggestions__bg {
    float: left;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .378);
    z-index: 1001;
}

.search-modal-background {
    background-color: $black;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1002;
}

.site-search {
    position: relative;
    height: 100%;


    @include media-breakpoint-up(sm) {
        margin-right: 0;
        min-width: 400px;
    }

    @include media-breakpoint-up(xl) {
        margin-left: 40px;
        min-width: 500px;
    }

    @include media-breakpoint-only(md) {
        min-width: 260px;
    }

    @include media-breakpoint-only(sm) {
        min-width: 170px;
    }

    .searchBar {
        display: flex;
        align-items: center;
    }

    .fa-close,
    .fa-search {
        position: absolute;
        border: none;
        top: 0.5625em;
        right: 0.5625em;
        padding: 0;
        background-color: transparent;
    }

    .reset-button {
        position: absolute;
        border: none;
        top: 0.5625em;
        right: 2em;
        padding: 0;
        background-color: transparent;
    }

    .form-control:focus {
        box-shadow: none;
    }

    input {
        border: none;
        border-radius: 5px 0px 0px 5px;
        padding-right: 2rem;
        font-size: 12px;
        color: #646464;
        height: 40px;
    }

    .search__input-close{
        color: #002B70;
        background-color: #EAEAEA;
        border-radius: 5px;
        padding: 5px 22px 5px 10px;

        cursor: pointer;
        float: right;
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);

        span{
            float: right;
            font-size: 1.8em;
            position: absolute;
            right: 8px;
            top: -1px;
        }
    }

    .searchButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $brandColor2;
        border: none;
        border-radius: 5px;
        height: 40px;
        width: 57px;
        i {
            font-size: 1.4rem;
            color: #fff;
        }
        &:disabled {
            opacity: 0.3;
        }
    }
}

@include media-breakpoint-down(xs) {
    .header-search {
        .site-search {
            display: none;
        }
    }
}

.suggestions-wrapper {
    position: relative;

    .modal {
        display: block;
        height: auto;
    }
}

.suggestions_modal {
    position: absolute;
    border: 0;
    background-color: $white;
    top: 45px;
    right: 0px;
    width: 100%;
    z-index: 3;
    font-size: 14px;
    border-radius: 5px;

    .suggestions-titles {
        color: #002b70;
        font-size: 14px;
        font-weight: 700;
    }

    .swatch-circle {
        width: 38px;
        height: 38px;
    }

    .swatch-circle {
        @include swatch(2.5em, $white);

        margin-right: 0.85em;
    }

    .header {
        font-size: 0.875em;
        padding-top: 0.625em;

        &:not(:first-child) {
            border-top: 0;
        }
    }

    .items {
        padding: 0.313em 0;

        .list {
            padding: 0;
            list-style-type: none;
        }

        .link {
            text-decoration: none;

            &:hover .name {
                text-decoration: underline;
            }
        }
    }

    .item {
        padding-bottom: 0.625em;

        .name {
            margin-top: 0.313em;
        }

        .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        @include media-breakpoint-down(xs) {
            padding-bottom: 0.938em;
        }
    }

    .category-parent {
        color: $grey4;
        font-size: 0.875em;
    }

    .selected {
        background-color: $grey2;
    }

    .container {
        list-style-type: none;
    }


    .suggestions__container{
        display: flex;
        padding: 16px;
        justify-content: space-between;

        h3.searchSuggestions__option--title{
            font-size: 13px;
            color: #000;
            font-weight: bold;
            text-align: left;
            padding-bottom: 8px;
        }

        .searchSuggestions__mostSearched{
            width: 35%;

            ul.searchSuggestions__terms{
                padding: 0;
                margin: 0;
                list-style-type: none;

                li{
                    padding-bottom: 10px;
                    font-size: 12px;
                    a{
                        color: #646464;
                        text-decoration: none;

                    }
                    .searchSuggestions__badge{
                        color: #1062A4;
                        background-color: #EAEAEA;
                        font-size: 10px;
                        font-weight: bold;
                        height: 18px;
                        width: 18px;
                        display: inline-block;
                        padding: 2px;
                        text-align: center;
                        border-radius: 3px;
                        margin-right: 5px;
                    }
                    .searchSuggestions__icon{
                        height: 25px;
                        width: 25px;
                        display: inline-block;
                        padding: 2px;
                        text-align: center;
                        i{
                            font-size: 20px;
                            color: #0ED178;
                        }
                    }
                }
            }

            ul.terms_search {
                margin-bottom: 15px;

                li{
                    padding-bottom: 5px;
                    a {
                        display: flex;
                        vertical-align: middle;
                        align-items: center;
                    }
                }
            }
        }

        .searchSuggestions__items{
             width: 62%;

            .searchSuggestions__tile{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 8px;
                    margin-bottom: 16px;

                .searchSuggestions__product{
                    border: 1px solid #D0D0D0;
                    border-radius: 5px;
                    padding: 6px;
                    display: flex;
                    gap: 6px;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 140px !important;
                    text-decoration: none;

                    &:nth-child( n + 5 ) {
                        display: none;
                    }

                    .searchSuggestions__product--image{
                        height: 64px;
                        width: 100%;
                        text-align: center;
                        overflow: hidden;

                        img{
                            height: 100%;
                        }
                    }

                    .searchSuggestions__product--content{
                        position: relative;
                        p{
                            line-height: 12px;
                            color: #000;
                            max-height: 24px;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 2!important;
                            -webkit-box-orient: vertical!important;
                            overflow: hidden!important;
                            margin-bottom: 5px;
                        }

                        .searchSuggestions__product--productPrice{
                            display: flex;
                            flex-direction: column;
                            .searchSuggestions__product--label{
                                font-size: 8px;
                                color: #696969;
                            }
                            .searchSuggestions__product--price{
                                font-size: 13px;
                                color: #000000;
                                font-weight: bold;
                            }
                        }

                        .searchSuggestions__product--add{
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            right: 0px;
                            bottom: 0px;
                            margin: 0;
                            padding: 0;
                            width: 25px;
                            height: 25px;
                            font-size: 16px;
                            font-weight: 200;
                            color: #fff;
                            border-radius: 5px;
                            background-color: #0ED178;
                            cursor: pointer;
                            outline: none;
                        }
                    }
                }
            }
        }

        .searchFullSuggestions__items{
            width: 100% !important;

            .searchSuggestions__tile{
                .searchSuggestions__product{
                    width: 150px !important;
                    display: block;

                    &:nth-child( n + 7 ) {
                        display: none;
                    }
                }
            }
        }
    }

    .searchSuggestions__link{
        color: #fff;
        background-color: #00744a;
        border-radius: 5px;
        width: 100%;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        padding: 8px;
        text-decoration: none;
    }
}

.more-below {
    -moz-border-radius: 1.25em;
    background: $grey3;
    border: 0.063em solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25em;
    bottom: 1.875em;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    display: none;
    height: 2.5em;
    position: fixed;
    right: 1.875em;
    width: 2.5em;

    i.fa-long-arrow-down {
        border-radius: 50%;
        color: $white;
        display: table-caption;
        height: 0.75em;
        font-size: 1.5rem;
        left: 0.57em;
        line-height: 0.8em;
        position: absolute;
        top: 0.4em;
        width: 0.8em;
    }
}

@media (max-width: 1200px) {
    .suggestions_modal {
        .suggestions__container{
            flex-direction: column;
            gap: 16px;

            .searchSuggestions__mostSearched, .searchSuggestions__items{
                width: 100%;

                .searchSuggestions__tile .searchSuggestions__product:nth-of-type(n+4){
                    border-bottom: 0px;
                }
            }
        }

        ul.searchSuggestions__terms li:nth-of-type(n+6) {
            display: none;
        }
    }
}

@media (max-width: 991px){
    .suggestions_modal {
        .suggestions__container{
            .searchSuggestions__items .searchSuggestions__tile .searchSuggestions__product {
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 543px){
    .suggestions_modal {
        .suggestions__container{
            gap: 14px;

            h3.searchSuggestions__option--title {
                font-size: 14px;
                padding-bottom: 8px;
            }

            .searchSuggestions__mostSearched ul.searchSuggestions__terms li {
                font-size: 13px;

                .searchSuggestions__badge {
                    font-size: 13px;
                    height: 25px;
                    width: 25px;
                }
            }

            .searchSuggestions__items .searchSuggestions__tile {
                gap: 15px;

                .searchSuggestions__product {
                    gap: 12px;
                    flex-direction: row;
                    border-radius: inherit;
                    border: none;
                    border-bottom: 1px solid #ccc;
                    padding: 0 0 10px 0;

                    &:last-of-type {
                        border: none;
                    }

                    .searchSuggestions__product--image{
                        width: 60px;
                        height: 60px;
                        display: flex;

                        img{
                            width: 100%;
                            height: auto;
                        }
                    }

                    .searchSuggestions__product--content {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 6px;
                        text-align: left;
                        width: 100%;

                        p{
                            margin-bottom: 0px;
                            font-size: 12px;
                            line-height: 14px;
                            max-height: 28px;
                            font-weight: 400;
                        }

                        .searchSuggestions__product--productPrice{
                            text-align: right;
                            width: 145px;
                            .searchSuggestions__product--label{
                                font-size: 10px;
                            }
                            .searchSuggestions__product--price{
                                font-size: 14px;
                            }
                        }

                        .searchSuggestions__product--add{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

