//switch customized 
.custom-switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 25px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: #002B70;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #D0D0D0;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(17px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

//custom checkbox
.styled-checkbox{
    appearance: none;
    position: relative;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #D0D0D0;
    border-radius: 3px;

    &::before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7px;
        height: 7px;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: translate(-50%, -50%) scale(0);
        transform-origin: center;
        transition: 0.125s transform ease-in-out;
        box-shadow: inset 1rem 1rem #fff;
    }

    &:checked{
        background-color: #002B70;
        border-color: #002B70;
        &::before{
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &:disabled{
        opacity: 0.25;
    }

    &:focus{
        border-color: #002B70;
    }
}