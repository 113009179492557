#cepModal {
    background: rgba(0, 0, 0, 0.5);
    display: none;
}

.content-button-edit {
    span {
        text-decoration: underline;
        font-size: 14px;
    }
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background: #dbd7d7;
    border-color: #b9b7b7;
    color: black;
    opacity: 0.32;
}

.col-10.myAddressesModal {
    p {
        color: #000;
        font-size: 12px;
        font-weight: 400;
        margin: 0px;
    }
}

.modal-shipping {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 100dvh;
    pointer-events: all;
    margin: auto;
    padding: 1rem;

    @media (min-width: 992px) {
        max-width: 650px;
    }

    .modal-content {
        pointer-events: all;
        margin: auto;
        padding: 0;
        @media (min-width: 992px) {
            min-width: 600px;
        }

        @media (max-width: 992px) {
            .img-modal {
                margin-top: -30px;
            }
        }

        .form-input-cep {
            margin: 0 !important;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .btn-outline-primary {
            &:hover {
                border-color: #0ed178;
                color: #0ed178;
            }
        }

        .form-group {
            margin: 0;
        }
    }
}

.modal-header {
    position: relative;
    border: none;
    padding-top: 0px;

    .modal-localization {
        color: #000;
        font-size: 13px;
        font-weight: 600;
        text-decoration-line: underline;
        border-color: transparent;
        background: #FFF;
    }

    .title-container {
        align-items: flex-end;
        justify-content: end;
        flex-wrap: wrap-reverse;
        gap: 0 2rem;

        .modal-title {
            flex: 1 1 auto;
        }

        .close-button {
            all: unset;
            cursor: pointer;
            width: 2rem;
            height: 2rem;

            img {
                width: 100%;
            }
        }
    }
}

.form-row {
    margin-right: 0px;
    margin-left: 0px;
}

.locationIcon {
    margin-right: 10px;
    margin-left: 10px;
}

.locationAddress{
    display: -webkit-box;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-wrap: wrap;
}
.container-inputCep{
    display: flex;
    gap: 12px;
    align-items: center;
}

.input-content-cep {
    width: 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.label-form {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 300;
}

p.textCep {
    margin-top: 5px;
    color: #002b70;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
}

.input-form {
    border-radius: 5px;
    border: 0.5px solid #999;
    background: #fff;

    &:focus {
        border: 1px solid #3d3d3d;
    }
}

p.textCep {
    margin-top: 5px;
    color: #002b70;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
}

#inputStateModal {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

#inputNumberModal {
    text-align: center;
}

.modal-title {
    color: #002b70;
    line-height: normal;
    text-align: left;
}
.modal-subtitle {
    color: #002b70;
    line-height: normal;
    font-style: normal;
    margin-bottom: 0px !important;
}

.modal-location{
    margin-top: 30px;
    display: flex;
    flex-wrap: nowrap;
    p{
        font-size: 13px;
        text-decoration: underline;
        margin: 4px 0px 0px 4px;
    }
}

.btn-outline-primary{
    height: 51px;
    border-radius: 5px;
    background: #fff;
    color: #00744A;
    font-size: 15px;
    width: 100%;
    border: 2px solid #00744A;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
        color: #00744A;
        background: #fff;
        text-decoration: none;
    }
}

.modal-button {
    height: 51px;
    border-radius: 5px;
    background: #00744A;
    color: #fff;
    font-size: 15px;
    width: 100%;
    padding-top: 12px;

    &:hover {
        color: #fff;
        background: #00744A;
        text-decoration: none;
    }
}


.txt-modal {
    color: #002b70;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    text-decoration: underline;
    &.txt-modal-logged {
        text-decoration: none ;
        color:#333333;
    }
}


.title-standard,
.title-standard-edit {
    color: #23346c;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}

.cardCep {
    cursor: pointer;
    margin-bottom: 12px;
    padding: 12px;
    &.selectedAddress-card{
        border-color: #00744A;
        border-width: 2px;
    }
    border-radius: 5px;
    border: 0.5px solid #999;
    width: 100%;

    .card-body {
        padding-top: 12px;
        padding-bottom: 12px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    div.myAddressesModal {
        align-items: flex-start;
        flex-direction: column;
    }

    p {
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        margin: 0px;
        @media(max-width:992px){
          font-size: 12px;
        }

    }
    .title-standard,
    .title-standard-edit {
        color: #23346c;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }
    .content-button-edit {
        padding: 0px;
        text-align: center;

        .button-edit-modalCep,.redirect-editAddress {
            color: #002b70;
            font-size: 12px;
            font-weight: 600;
            text-decoration: none;
        }
    }
}
.newCepFields {
    .content-button-edit {
        display: flex;
        justify-content: end;
    }

    .button-edit-modalCep {
        color: #002b70;
        font-size: 12px;
        font-weight: 600;
    }

    .modal-body {
        padding: 0;

        .address-form {
            margin-top: 0;
        }
    }
}

.btn-input-cepedit {
    border-radius: 0px 5px 5px 0px !important;
    background: #0ed178;
    color: #fff;
    width: 63px;

    &:hover {
        color: #fff;
        background: #06b466;
    }
}

.cep-mobile{
    padding-bottom: 1rem;

    .nav-link.locationIcon{
        align-items: baseline;
        justify-content: flex-start;
        gap: 8px !important;
    }
}

@media (min-width: 992px) {
    #cepLinkText{
        max-width: 40ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .locationHeader {
            i {
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 768px) {
    #cepLinkText{
        .locationHeader {
            background: white;
            width: 100%;
            margin: 0 8px 8px;
            border-radius: 10px;
            padding: 4px 8px;
            justify-content: space-between;
            align-content: center;
            i {
                font-size: 12px;
            }
        }
    }
}

/**
.popover-cep{
    background-color: #fff;
    border-radius: 5px;
    padding: 28px;

    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.25);

    position: absolute;
    top: 90%;
    right: 18%;
    z-index: 2;

    display: none;
    flex-direction: column;
    width: 340px;
    transition: all 0.2s ease-in-out;

    .arrow-top {
        margin: 0 auto;
        width: 0;
        height: 0;
        border-right: 12px solid transparent;
        border-top: 12px solid transparent;
        border-left: 12px solid transparent;
        border-bottom: 12px solid #fff;
        position: absolute;
        top: -23px;
        left: 47%;

        transition: all 0.2s ease-in-out;
   }

    h3{
        color: #002B70;
        text-transform: uppercase;
        font-size: 18px;
        text-align: left;
    }

    p{
        color: #002B70;
        text-transform: uppercase;
        font-size: 12px;
    }
    .btn-popover{
        height: 56px;
        width: 100%;
        border-radius: 5px;
        background-color: #00744A;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        display: grid;
        align-items: center;
    }

    .close-btn{
        position: absolute;
        right: 0px;
        top: 0px;
        width: 40px;
        height: 40px;
        z-index: 1;

        button{
            width: 40px;
            height: 40px;
            img{
                width: 20px;
                height: 20px;
                vertical-align: baseline;
            }
        }
    }
}
*/
/**
@media (max-width: 768px) {
    .popover-cep{
        left: 16px;
        top: 100%;

       .arrow-top {
            top: 28px;
       }
    }
}
*/
.divider-cep{
    height:14px;
    width:100%;
    margin:0;
    text-align:center;
    border:0;
    border-bottom: 1px;
    border-color:#C9C9C9;
    border-style:solid;
    color:#C9C9C9;

    span{
        padding:0 10px;
        height:20px;
        line-height:20px;
        display:inline-block;
        font-size: 14px;
        background-color: #fff;
    }


}

.form-input-cep{
    align-items: flex-end;
    justify-content: space-between;

    .invalid-feedback{
        position: absolute;
    }
}

.form-input-cep:has(.form-group input.is-invalid) {
    margin-bottom: 15px;
}
.opt-address{
    border-top: solid 1px #D0D0D0;
    padding: 24px 0;

    @media (max-width:992px){
        padding: 12px 0;  
    }
}
.modal-list-address{
    display: inline-block;
    width: 100%;

    .modal-list-scroll {
        width: 100%;
        height: 100px !important;
        overflow-y: auto;
        overflow-x: hidden;
        float: left;
        padding-right: 5px;
    }

    .modal-list-scroll::-webkit-scrollbar {
        width: 5px;
    }

    .modal-list-scroll::-webkit-scrollbar-thumb {
        background: #00744A;
        border-radius: 20px;
    }

    .modal-list-scroll::-webkit-scrollbar-track {
        background: #DEDEDE;
        border-radius: 20px;
    }
}
.modalCepContent{
    max-width: 400px;
    border-radius: 10px;
    
    .modal-header{
        display: block;
        padding: 24px 24px 0px 24px;
        @media (max-width:992px) {
           padding: 12px 12px 0px 12px; ;
        }

        .modalCep-header{
            display: flex;
            flex-direction: column;
            gap: 24px;
            @media (max-width:992px) {
                gap: 12px;
            }
        }

        h3 {
            font-size: 24px;
            @media (max-width:992px) {
                font-size: 18px;
            }
        }
        .modal-subtitle{
            font-size: 18px;
            @media (max-width:992px) {
                font-size: 14px;
            }
        }
        .close{
            display: block;
            font-size: 20px;
            color: #D0D0D0;
        }
    }

    .modal-body{
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 24px;
        @media (max-width:992px){
            gap: 12px;
            padding: 12px;
        }
    }

    .modalCep-form-onlyCep,.modalCep-form-allFields{
    
        .form-group{
            input {
                width: 100%;
                height: auto;
                border: 1px solid #D0D0D0;
                border-radius: 5px;
                padding: 8px 12px;
                font-size: 14px;
                margin-bottom: 12px;
                &::placeholder{
                    color: #D0D0D0;
                }

                &:focus{
                    outline: none; 
                }

                @media (max-width: 992px){
                    padding: 5.5px 12px;
                }
            }
        }
    }

    .btn-outline-primary {
        height: auto;
        font-size: 16px;
        font-weight: 700;
        border: 1px solid #00744a;
        padding:8px ;

        @media (max-width:992px){
            font-size: 14px;
            padding:5.5px ;
        }
    }
    .modalCep-form-allFields{
        .btn-modal-cep.btn-outline-primary{
            padding: 9.5px;
        }
    }
    
    .btn-modal-cep{
        background: #00774A;
        color: #fff ;
        border: none;
        &:hover {
            color: white !important;
            border: none;
        }
        &:disabled{
            color: #948d8d;
            background: #D0D0D0
        }
    }

    .btn-modalCep {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        gap: 8px;
        color:#002B70;
        background: transparent;
        border: none;
        @media (max-width:992px){
            font-size: 14px;
        }
    }
    .img-modal{
        border-radius: 10px;
        margin-top: -50px;
    }
}


@media (max-width:768px) {
   
    /**
    .popover-cep{
        margin: 0 auto;
        right: 5%;
    }
    */

    #cepModal .modal-shipping{
        width: 90%;
        padding: 0px !important;

        .modal-content {
            // width: 100%;
            // height: 100%;
            // max-width: inherit;

        }
    }

    .modal-list-address{
        padding-bottom: 0px;
        .list-address{
            width: 100%;
        }

        .modal-list-scroll {
            width: 100%;
            height: 100px !important;
        }
    }
}

@media (max-width: 768px) {
    #cepModal .modal-shipping{
        overflow: hidden;
    }
    .modalCepContent{
        .modal-location {
            margin-top: 15px;
        }
        .txt-modal {
            font-size: 12px;
        }
    }
}