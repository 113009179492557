@import "../experience/components/suzanoLayouts/categoryGrid";

.containerPageError{
    border-radius: 5px;
    border: 0.5px solid #D0D0D0;
    background-color: #FFFFFF;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    svg{
        margin-top: 5%;
    }

    h1{
        color: #000;
        text-align: center;
        font-family: Open Sans;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    p{
        color: #696969;
        text-align: center;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

#btnpageHome{
    border-radius: 5px;
    border: 1px solid #02754B;
    width: 308px;
    height: 51px;
    flex-shrink: 0;
    color: #02754B !important;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    align-content: center;
    flex-wrap: wrap;
    cursor: pointer;
}