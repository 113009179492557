.no-decoration {
    text-decoration: none;
}

.addressList-container {
    margin-top: 27px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 11px;
    a{
        text-decoration: none;
    }

    .addressList-card {
        width: 100%;
        max-width: 320px;
        height: 190px;
        border: 1px solid #D0D0D0;
        border-radius: 5px;
        padding: 22px 26px;

        &:first-child {
            border-color: #002B70
        }

        .addressList-title {
            display: flex;
            justify-content: space-between;
            border-bottom: 0.5px solid #D0D0D0;
            padding-bottom: 12px;
            margin-bottom: 13px;

            h2 {
                margin: 0;
                color: #000000;
            }

            p {
                margin: 0;
                color: #000000;
            }
        }

        .addressList-address {
            margin-bottom: 8px;

            p {
                margin: 0;
                font-size: 12px;
                color: #000000;
                font-weight: 400;
            }
        }

        .addressList-options {
            display: flex;
            align-items: center;
            white-space: nowrap;

            a,
            button {
                color: #23346C !important;
                text-decoration: none;

                &:focus {
                    outline: none;
                }
            }

            .editAddress::after {
                content: "|";
            }

            .setDefaultAddress {
                &:before {
                    content: "|";
                }
            }

            .remove-address {
                padding: 0 10px;
                text-align: start;
                background: none;
                border: none;

            }
        }

        &.newAddress-card {
            border-color: #D0D0D0;

            .addressList-newAddress {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                gap: 30px;

                .plusIcon {
                    content: url(../../images/myAccount/iconPlus.svg);
                }

                span {
                    color: #000000;
                }
            }
        }
    }
}

#deleteAddressModal,
#removeProductModal{
    *:focus {
        outline: none;
        box-shadow: none;
    }

    span {
        font-size: 20px;
        color: #002B70;
        font-weight: 700;
        text-transform: uppercase;
    }

    button .product-to-remove {
        font-weight: 700;
    }

    .modal-dialog {
        top: 25%;
    }

    .modal-footer {
        .cancel-button {
            border: solid 1px #000000;
            color: #000000;
        }
    }
}

#deleteAddressModal,
#removeProductModal,
#alertRemoveCouponModal{
    .modal-footer {
        button {
            font-size: 15px;
            font-weight: 700;
            width: 50%;
        }
        .delete-coupon-modal-confirmation-btn,
        .delete-coupon-confirmation-modal-btn {
            background: #0ED178;
            border-color: #0ED178;
            color: #FFFFFF;
        }

        .btn-outline-primary {
            color: #0ED178;
            border-color: #0ED178;
        }
        .btn-outline-primary:hover {
            color: #fff;
            background: #0ED178;
        }

        .delete-coupon-confirmation-modal-btn{
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }
}

@media (max-width: 1200px) {
    .addressList-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 992px) {
    .addressList-container {
        grid-template-columns: 1fr;

        .addressList-card {
            max-width: 100%;
        }
    }
}
@media(max-width: 768px) {
    .addressList-container {
      padding: 15px;
    }
}