@import "productCommon";

.product-tile {
    height: 400px;
    width: 100% !important;
    border: 1px solid #D0D0D0;
    background: #FFFFFF;
    padding: 22px 18px 20px 19px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.notAvailable {
    margin-bottom: 0;
}

.promotion_tag {
    font-family: "Inter";
    font-size: 8px;
    font-weight: 700;
    padding: 3px;
    border: solid 1px #03267F;
    border-radius: 5px;
    background: #E6EAF1;
    color: #03267F;
}

.component-price-buttom{
    display: flex;
    gap: 6px;
    flex-direction: column;
}

.productTileBox {
    .price .strike-through {
        text-decoration: line-through;
        color: #999;
        margin-right: 4px;
        font-size: 9px;
        font-weight: 500;
    }

    .product-price {
        .price {
            font-size: 8px;
        }
    }
}

.product-tile-box {
    margin-top: -30px;
    height: 100%;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-container {
        min-height: 150px;
        display: flex;
        align-items: center;
    }

    .priceBox {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        column-gap: 4px;
        p{
            color: black;
            font-style: normal;
            font-weight: 400;
            line-height: 135.9%;
            letter-spacing: 0.36px;
            font-size: 12px;
        }
        .price, .sales {
            font-size: 18px !important;
        }
    }

    .quantityAndShop {
        margin-top: 0;
    }

    .tile-body {
        width: 100%;
        min-height: 136px;
        gap: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px;

        .pdp-link {
            margin-bottom: 0px;

            a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                /* autoprefixer: off */
                overflow: hidden;
                font-weight: bold;
            }
        }
    }
}

.product-tile .image-container a .tile-image{
    max-width: 123px;
    max-height: 123px;
    object-fit: contain;
}

.tile-body{
    .pdp-link{
        color: #000;
        font-size: 14px;
        font-family: 'Open Sans' !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal !important;
        margin-bottom: 70px;
        a{
            color: #000 !important;
        }
    }

    .priceBox{
        color: #000;
        font-size: 25px;
        font-family: 'Open Sans' !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

}

.qtdContainer.productCard {
    width: 100%;
    height: 100%;
    .quantity-form-tile {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #989898;
        .quantity {
            border: none;
            outline: none;
            text-align: center;
            width: 100%;
            font-weight: 700;
        }
    }
    button {
        width: 100%;
        max-width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 32px;
        font-weight: 200;
        &.increaseAmountCart {
            background: #0ed178;
            color: white;
            border-radius: 0px 4px 4px 0px;
            &:hover {
                background: #0DC16F;
            }
        }
        &.decreaseAmountCart {
            margin-top: -2px;
            border-radius: 4px 0px 0px 4px;
            color: #989898;
        }
    }
}


.quickBuyBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0ED178;
    border-radius: 5px;
    background: #0ED178;
    color: #FFF;
    text-align: center;
    line-height: normal;
    white-space: nowrap;
    padding: 10px;
    min-height: 28px;
    &:hover{
        text-decoration: none;
        background: #0DC16F;
        border: 1px solid #0DC16F;
    }
}

.quantityAndShop {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 22.8px;

    .is__loading:before {
        width: 20px;
        height: 20px;
        top: 26%;
        left: 44%;
    }
}

#minicart {
    .is__loading:before {
        height: 20px !important;
        top: 18% !important;
        left: calc(50% - 15px) !important;
    }
}

.quantityInput__control {
    border: 0.5px solid #000;
    border-radius: 5px;
    height: 46px;
}

.quantityInput {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .quantityInput__control {
        display: flex;
    }

    & button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 0 5px;
        color: #000;
    }

    & input {
        border: none;
        outline: none;
        font-weight: 600;
        width: 1ch;
        padding: 0;
        text-align: center;
    }
    & input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.favIconAndDiscount {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    z-index: 4;
}

.sales .value {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.productTile-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.productDetails__promotionItem {
    font-size: 14px;
    border-radius: 5px;
    width: 82px;
    height: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans';
}

@media (max-width: 835px) {

    .product-tile {
        width: 247px;
        height: 420px;
        margin: 0px;
        padding: 20px 20px 22px 19px;
        justify-content: space-between !important;

        .quantityAndShop {
            margin-top: 26px;
            .quickBuyBtn {
                padding: 8px;
            }
        }

        .quickBuyBtn {
            color: #FFF;
            text-align: center;
            font-style: normal;
            line-height: 109.523%;
        }

        .product-tile-box {
            .product-tile-body {
                figcaption {
                    width: 100%;
                }

                .pdp-link {
                    margin-bottom: 28px;
                }
            }
        }
    }
}

@media (max-width:430px){
    .product-tile {
        width: 250px;
        height: 342px;
        margin: 0px;
        margin-right: 26px;
        padding: 16px;

        .product-tile-box {
            .product-tile-body {
                min-height: 140px;
                figcaption {
                    width: 100%;
                }
                .pdp-link {
                    margin-bottom: 16px;
                }
            }
        }
        .quantityAndShop {
            margin-top: 16px;
            .quickBuyBtn {
                padding: 8px;

            }
        }
    }

}