.aboutUsContainer {
    display: flex;
    justify-content: center;
}

.containerGeneralInstitucional {
    display: flex;
    width: 90%;
    flex-direction: column;
    gap: 45px;
}

.containerInstitucional {
    display: flex;
    gap: 5%;

    h2 {
        color: #02754b;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        margin-top: -2%;
        margin-bottom: 3%;
    }
}

.secoes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    min-width: 170px;

    h1 {
        color: #23346c;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    ul {
        list-style-type: none;
        color: #000;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    #LIativo, .active {
        color: #02754b;
        font-weight: 700;
    }
}

.containerContentInstitucional {
    p {
        color: #000;
        font-family: Open Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
    }

    h3 {
        color: #000;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
    }
}

.containerDescricaoInstitucional {
    flex-direction: column;
    border-radius: 5px;
    border: 0.5px solid #d0d0d0;
    background: #fff;
    padding: 4%;

    img.institucional {
        width: 100%;
    }
}

.breadcrumbsInstitucional {
    margin-top: 2%;
    color: #a0a0a0;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
        color: #000;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.siteMap-header {
    background: #f6f6f6;
    h1 {
        text-align: center;
    }
    .siteMap-search {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 45px;

        input {
            width: 100%;
            padding: 12px;
            max-width: 550px;
            border: solid 1px #eaeaea;
            border-radius: 5px 0 0 5px;
            background: #ffffff;
            height: 40px;
            font-size: 12px;
            color: #646464;
            outline: none;
            border-right: none;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $brandColor2;
            border: none;
            border-radius: 5px;
            height: 40px;
            width: 50px;
        }
    }
}

.siteMap-container {
    background: #ffffff;
    padding: 0 2rem;

    .wrapper {
        margin: auto;
        max-width: 1000px;
    }

    .siteMap-component {
        padding: 3rem 0;
        margin: 1rem 0;

        &:not(:last-of-type) {
            border-bottom: solid 1px #cbcbcb;
        }
    }

    .siteMap-titles {
        text-align: center;
        margin-bottom: 3rem;
    }

    .department-list,
    .category-list,
    .footer-information {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
        gap: 0 2rem;
        list-style: none;
        padding: 0;

        li {
            border-right: 1px solid #dfdfdf;
            padding-bottom: 0.5rem;
        }

        ul {
            list-style: disc;
            padding-left: 2rem;

            a {
                font-weight: 400;
            }
        }

        a {
            font-size: 14px;
            font-weight: 600;
            color: $brandColorDarker1 !important;
            list-style: none;
            padding: 0;
        }
    }

    .department-list {
        li {
            padding-bottom: 1.5rem;

            h3{
                margin-bottom: 0px;
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .category-list {
        .label {
            display: block;
            margin: 0;
        }

        .subcategory-list {
            li {
                border: none;
                padding: 0;

                &::marker {
                    content: "• ";
                    color: #002b70;
                }
            }
        }
    }

    .footer-information {
        .title {
            display: none !important;
        }

        .content-asset {
            border-right: 1px solid #dfdfdf;

            ul {
                display: flex;
                flex-direction: column;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    font-size: 14px;
                    font-weight: 600;
                    border: none;
                    padding-bottom: 1.5rem;
                }
            }
        }
    }
}
