
.blue{
    color: $brandColor1;
}
.darkBlue{
    color: $brandColorDarker1;
}

.green{
    color: $brandColor2;
}
.darkGreen{
    color: $brandColorDarker2;
}
