.form-group {
    &.required .form-control-label::after {
        content: "*";
        color: $danger;
    }
    &.required .form-control-label::before {
        content: "";
        color: #4A4A4A;
    }

    label {
        font-size: 12px;
        color: #4A4A4A;
        display: flex;
    }

    input,select {
        height: 48px;
        border-radius: 5px;
    }

    .form-control:focus{
        border: 1px solid #ced4da;
    }

    &.passwordField{
        position: relative;
        input {
            padding-right: 40px;

        }
        img {
            position: absolute;
            cursor: pointer;
            right: 30px;
            top: 43px;
        }
    }
}

.tooltip-teste {
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    padding-left: 5px;
    span {
        position: absolute;
        text-align: center;
        visibility: hidden;
        background-color: #0ed178;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
        &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -8px;
            top: 15.5px;
            left: 0px;
            border-bottom: 8px solid transparent;
            border-right: 8px solid #0ed178;
            border-top: 8px solid transparent;
        }
    }
}
span:hover.tooltip-teste span {
    width: 109%;
    visibility: visible;
    opacity: .9;
    top: -17px;
    left: 20px;
    z-index: 999;
}

@media(max-width:769px){
    span:hover.tooltip-teste span {
        width: 40%;
        top: -17px;
        left: 21px;
    }
}

@media(max-width:425px){
    span:hover.tooltip-teste span {
        width: 72%;
        top: -17px;
        left: 21px;
    }
}

.saveButton-container{
    margin-top: 25px;
    display: flex;
    .saveButton-myAccount{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 700;
        height: 50px;
        width: 100%;
        background: #0ED178;
        color: #FFFFFF;
    }
}

.saveMessage-container {
    display: flex;
    align-items: center;
    span {
        margin-top: 25px;
        color: #0ED178;
    }
    .messageSucess {
        display: none;
    }
}

.address-form {
    margin-top: 30px;

    .formAddress-btn {
        margin-top: 25px;
        a,
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 700;
            height: 50px;
        }

        a.cancelAddress-button {
            border: solid 1px #000000;
            width: 100%;
            color: #000000 !important;
        }

        .saveAddressMyAddress-btn {
            width: 100%;
            background: #0ED178;
            color: #FFFFFF;
        }
    }
}

select.gray-card-form {
    padding: 6px 12px;
    &:disabled {
        background-color: #e9ecef;
        opacity: 1;
    }
}

.form-title {
    margin-bottom: 18px;
    color: #23346c;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.btn-submit {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 700 !important;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    background: #0ED178 !important;
    color: #FFFFFF !important;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: #067d48 !important;
        color: #FFFFFF;
    }
}

#register {
    .form-group input{
        &::placeholder{
            color:#D0D0D0;
        }
    }
}