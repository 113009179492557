$modal-fade-transform: scale(.8);

#minicart {
    background: hsla(0, 0%, 0%, 0.378);
    z-index: 1050 !important;

    .modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;

        &.show .modal-dialog {
            animation: slideIn 0.5s ease-out forwards;
        }

        &.hide .modal-dialog {
            animation: slideOut 0.5s ease-out forwards;

        }
    }

    .modal-dialog {
        position: fixed !important;
        margin: 0;
        width: 430px;
        height: 100%;
        padding: 0;
        right: 0;

        @media (max-width: 576px) {
            bottom: 0px;
        }

        &.modal.fade .modal-dialog {
            transform: translate3d(100%, 0, 0);
        }

        &.modal.show .modal-dialog {
            transition: transform 1s ease-out;
            transform: translate3d(0, 0, 0);
        }
    }

    .modal-dialog-scrollable {
        max-height: 100vh;

        @media (max-width: 576px) {
            max-height: 90svh;
        }
    }

    .modal-content {
        cursor: initial;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        align-items: stretch;
        flex-wrap: nowrap;
        height: 100svh !important;
        max-height: 100vh !important;
        border-color: #FFF;
        box-shadow: none;
        border-radius: 10px 0px 0px 0px;
        @media (max-width: 992px) {
            border-radius: 10px;
        }
    }

    .modal-header {
        flex-shrink: 0;
        height: 34px;
        padding: 24px;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            height: 28px;
        }

        .close {
            padding: unset;
            margin: unset;
            float: unset;
        }

        .btn-close-cart {
            display: none;
        }
    }

    .modal-title {
        color: $brandColorDarker1;
        line-height: 135.9%;
        letter-spacing: 0.72px;
        text-transform: uppercase;
    }

    .modal-body {
        width: 100%;
        font-weight: 300;
        overflow: auto;
        padding: 0 24px !important;
        margin: 0;
        margin-top: 15px;
        flex: 1 1 auto !important;
        max-height: calc(100svh - 190px);
        @media (max-width: 992px) {
            max-height: calc(100svh - 250px);
        }
        overflow-x: hidden;

        .productTileBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            align-items: flex-start;
            margin: unset;
            padding: 12px 0px;
            height: fit-content;
            gap: 12px;
            picture  {
                width: unset;
            }
        }

        .product-tile-pd {
            width: 100% !important;
            max-width: 100% !important;
            height: fit-content;
            padding: unset;
            border: 0;
            border-radius: 0;
            border-top: 1px solid #d0d0d0;
            box-shadow: none;
            margin: 0;

            &:first-child {
                border-top: none;
            }
        }

        .product-tile-image {
            width: 80px;
            min-height: 80px;
            border: none;
            padding: 0;

            .product-tile-component-image {
                max-width: 80px;
                max-height: 80px;
                width: auto;
                height: auto;
                object-fit: contain;
            }
        }

        .product-tile-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: auto !important;
            gap: 12px;
            padding: 0px;

            .product-cart-tile-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 8px;
                .product-name-link {
                    height: auto;
                }
            }
        }

        .qtdContainer.productCard {
            width: 176px !important;
            @media (max-width: 992px) {
                width: 160px !important;
            }
            height: 30px !important;

            input {
                &:not(:focus) {
                    &[value]:after {
                        content: 'un';
                        position: absolute;
                        right: 10px;
                    }
                }
            }

            .quantity-form-tile {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border: 1px solid #989898;

                .quantity {
                    border: none;
                    outline: none;
                    text-align: center;
                    width: 100%;
                    position: relative;
                    left: -5px;
                    top: 0px;
                    font-weight: 600;
                }
            }

            button {
                width: 100%;
                max-width: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 100%;
                font-size: 20px !important;
                font-weight: 200;

                &.increaseAmountCart {
                    background: #0ed178;
                    color: white;
                    border-radius: 0px 4px 4px 0px;

                    &:hover {
                        background: #0DC16F;
                    }
                }

                &.decreaseAmountCart {
                    border-radius: 4px 0px 0px 4px;
                    color: #989898;
                    margin-top: -2px;
                    text-align: center;
                }
            }
        }

        .productTile__price_qnt--container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            justify-content: space-between;

            // @media (max-width: 576px) {
            //     flex-direction: column;
            //     gap: 4px;
            // }

            /*.quantityAndShop{
                flex-shrink: 0;
                flex-grow: 1;
                flex: 0 0 100%;
            }*/
        }

        .productTile__price--container {
            flex-direction: column;

            // @media (max-width: 576px) {
            //     flex-direction: row;
            // }

            .unit-price-label {
                color: #808080;
            }

            .product-price {
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
            }
        }

        .removeBtn {
            content: url(../images/trash.svg);
        }

        .minicart .remove-btn {
            background-color: transparent;
        }
    }

    .modal-footer {
        padding: 0px;
        border-top: 0px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;

        .modal-footer-cta {
            @keyframes slideUp {
                from {
                    transform: translateY(100%);
                    opacity: 0;
                }
                to {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            @media (max-width: 992px) {
                position: fixed;
                bottom: 0;
                width: 100%;
                animation: slideUp 0.5s ease-out forwards;
                transform: translateY(100%);
                opacity: 0;
            }


            padding: 24px 12px;
            box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.25);
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            gap: 12px;
            background: $bgLighter;
            z-index: 1;
            margin-left: 0px;
        }

        @media (max-width: 576px) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .footer-summary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .summary-right {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                align-items: center;
                gap: 5px;
            }

            .summary-text {
                color: $bgBlack;
                font-style: normal;
                line-height: 24px;
                letter-spacing: -0.12px;
                margin-bottom: 0;
            }

            .summary-caption {
                color: $bgBlack;
                text-align: right;
                font-style: normal;
                font-weight: 400;
                line-height: 135.9%;
                letter-spacing: 0.4px;
                margin-bottom: 0;
            }
        }

        .btn {
            display: flex;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 5px;
            background: $brandColor2;
            color: $bgLighter;
            font-style: normal;
            line-height: 24px;
            /* 133.333% */
            letter-spacing: -0.18px;
            margin: 0;

            &:hover {
                background: $brandColorDarker1;
                ;
            }
        }

        a.normal {
            width: 100%;
            margin: unset;
            color: unset;
            text-decoration: unset;
        }

        button.btn {
            width: 100%;
        }

        .btn-see-cart {
            display: none;
        }
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background: #f1f3f5;
        border-left: 1px solid darken(#f1f3f5, 10%);
    }

    ::-webkit-scrollbar-thumb {
        background: $brandColorDarker3;
    }

    button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        color: inherit;
        font: inherit;
        text-align: inherit;
        cursor: pointer;
        outline: none;
    }

    .unit {
        display: block;
        position: relative;
        left: -7px;
        top: 6px;
    }

    @media(max-width: 768px) {

        .product-tile-pd {
            width: 100%;
        }

        .product-tile-pd .productTileBox .product-tile-body .product-name-link {
            margin-bottom: 0 !important;
        }

        .product-tile-pd .quantityAndShop {
            margin-top: 0 !important;
        }
    }

    @media(max-width: 576px) {
        .product-tile-pd .quantityAndShop {
            justify-content: space-between;
        }

        .modal-dialog.modal-dialog-scrollable {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    .add-to-productlist-login-btn {
        text-decoration: none;
    }

    .favIconAndDiscount {
        i {
            padding: 0;
        }
    }

    i.icon-favorite_border {
        width: 18px;
        height: 18px;
        color: #0ED178;
    }

}

@keyframes slideIn {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

.modal-minicart-shipping {
    display: flex;
    flex: 1;
    padding: 8px;
    width: max-content;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    background: #F6F6F6;

    i {
        width: 20px;
        height: auto;
        flex-shrink: 0;
        font-size: 20px;
    }

    .icon-tag{
        font-size: 20px;
    }

    span {
        color: #333;
        font-size: 12px;
        font-weight: 400;
    }

}

.modal-footer-cta .minicart-tags {
    opacity: 0;
    display: none;
}

.apply-shipping-free {
    background-color: #e0f7ec;
}

.modal-minicart-shipping-count {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 4px;
    min-width: 290px;

    .progress-bar {
        width: 100%;
        height: 4px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #D9D9D9;

        .progress-bar-fill {
            border-radius: 5px;
            transition: all .2s linear;
        }
    }
}

.minicart-show-mobile {
    display: none !important;

    @media (max-width: 576px) {
        display: block !important;
    }
}

.minicart-remove-btn-mobile {
    display: block !important;

    @media (max-width: 576px) {
        display: none !important;
    }
}

//### EFEITO TRANSIÇÃO DE MODAL
@media (max-width: 576px) {

    #minicart.modal {
        height: 0;
    }

    #minicart {
        // .modal-header {
        //     .close {
        //         display: none;
        //     }

        //     .btn-close-cart {
        //         display: block;
        //     }
        // }

        .modal-body {
            z-index: 1;
        }

        .btn-checkout-begin {
            display: none;
        }

        .modal-dialog.modal-dialog-scrollable {
            bottom: -100vh;
            transition: bottom .5s ease-out;

            .minicart-tags {
                opacity: 0;
                position: relative;
                bottom: -20vh;
                transition: all .5s ease-out;
            }

            .modal-footer-cta .minicart-tags {
                opacity: 1 !important;
                display: flex;
                bottom: 0vh;
                margin: 0 !important;
            }
        }

        .modal-dialog.modal-dialog-scrollable.show {
            bottom: 0vh;

            .minicart-tags {
                opacity: 1;
                bottom: 0vh;
                background-color: white;
            }

            .modal-footer-cta .minicart-tags {
                opacity: 0 !important;
                display: none;
            }

            .btn-see-cart {
                display: none !important;
            }

            .btn-checkout-begin {
                display: flex;
            }
        }

        .modal-footer {
            z-index: 2;

            .modal-footer-cta {
                padding-top: 12px;
            }

            .btn-see-cart {
                width: 100%;
                margin: unset;
                color: unset;
                text-decoration: unset;
                display: block !important;

                button {
                    width: 100%;
                    display: flex;
                    padding: 16px 24px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    align-self: stretch;
                    border-radius: 5px;
                    background: #0ed178;
                    color: #fff;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: -.18px;
                    margin: 0;
                }
            }
        }
    }
}

.minicart-tags{
    width: 100%;
    height: fit-content;
    padding: 12px 8px !important;
    margin-right: 0px !important;
    display: flex;
    gap: 8px;
    flex-direction: row;
}

@media (max-width: 576px) {
    .modal-footer-cta .minicart-tags{
        padding: 0px !important;
    }
}

.minicart-tags-scroll{
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        height: 1px;
        background: #fff !important;
        border: 0px;
    }

    &::-webkit-scrollbar-track {
        background: #fff !important;
    }

    &::-webkit-scrollbar-thumb {
        background: #fff !important;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #fff !important;
    }
}

.minicart-tags-scroll {
    width: 100%;
}