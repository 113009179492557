.itensPrivacidade{
    border-radius: 5px;
    background: #23346C;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
}

.accordion{
    width: 100%;

    h2{
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.accordion-body{
    color: #FFF !important;
    font-family: Open Sans !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 23px !important;

    p{
        color: #FFF !important;
    }
}

.accordion-body{
    background: #23346C !important;
}

.accordion-button{
    color: #FFF !important;
    font-family: Open Sans !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 23px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background: #23346C !important;
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

.accordion-item{
    border:none !important;
    border-bottom:1px solid white !important;
}

.secoes{
    a{
        color: #000;
        font-family: Open Sans !important;
        font-size: 13px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 23px !important;
        text-decoration: none !important;
    }
}