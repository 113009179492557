.experience-suzano_assets-text_block {
  .title {
    color: #02754b;
    font-family: 'Open Sans', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-top: -2%;
    margin-bottom: 3%;
  }

  .subtitle {
    margin-bottom: 18px;
    color: #23346c;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 0;
  }

  .text, p {
    font-size: 14px;
    line-height: 2;
    color: #696969;
    margin: 0;
  }
}