@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

//style pattern
@import '../../../theme_custom/main';
@import "../../../variables";

.product-tile-pd {
    height: max-content;
    max-width: 190px !important;
    margin: 0 auto;
    border: none;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 0;
    display: flex !important;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0px 1px 1px 0px #00000040;
    .favIconAndDiscount {
        .add-to-productlist-login-btn{
            text-decoration: none;
        }
        i {
            color: #0ED178;
            padding: 10px 10px 0 0;
            font-size: 18px;
        }
    }
    .productTileBox {
        margin-top: -30px;
        height: 100%;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        picture, figcaption  {
            width: 100%;
        }

        .product-tile-image {
            min-height: 180px;
            display: flex;
            align-items: center;
            padding: 15px;
            border-bottom: 1px solid #d0d0d0;
            width: 100%;
            justify-content: center;
            overflow: hidden;
    
            a .product-tile-component-image {
                max-width: 150px;
                max-height: 150px;
                object-fit: contain;
            }
        }

        .product-tile-body {
            font-size: 12px;
            padding: 6px;
            min-height: 136px;
            gap: 12px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .product-name-link {
                color: #000;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                height: 50px;

                h3{
                    font-size: 12px;
                    margin-bottom: 0px;
                    text-align: left;
                }

                a {
                    color: #000 !important;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    /* autoprefixer: off */
                    overflow: hidden;
                    font-weight: 400;
                    text-decoration: none !important;
                    @media (max-width:992px){
                        font-size: 11px;
                    }
                }
            }

            .price .strike-through{
                margin-top: 0;
                font-style: normal;
                font-family: "Roboto";
                font-weight: 800;
                line-height: 135.9%;
                letter-spacing: .36px;
                font-size: 8px;
                color: #808080;
            }
            .productTile__price--container {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                column-gap: 4px;
                margin-bottom: 0;

                .line-item-total-text{
                    font-family: "Roboto";
                    margin-bottom: 0px;
                    font-style: normal;
                    font-weight: 800;
                    line-height:normal;
                    letter-spacing: .36px;
                    font-size: 8px;
                    color: #808080;
                }
        
                .product-price {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    .sales, .sales .value, .price-content-tile .price{
                        color: #333333;
                        font-family: "Roboto";
                        font-size: 16px !important;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
    }

    .quantityAndShop{
        margin-top: 0px;

        .quickBuyBtn{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #0ED178;
            border-radius: 5px;
            background: #0ED178;
            color: #FFF;
            text-align: center;
            line-height: normal;
            white-space: nowrap;
            font-weight: 600;
            padding: 4.5px;
            height: max-content;
        }

        .quickBuyBtn:hover {
            background: #0DC16F;
            border: 1px solid #0DC16F;
        }

        .quick-shop {
            .quickBuyBtn {
                font-style: normal;
                line-height: 109.523%;
                font-size: 13px;
                width: 150px;
                border-radius: 5px;
            }
        }

        .qtdContainer.productCard {
            width: 100%;
            height: 28px;
            .quantity-form-tile {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border: 1px solid #989898;
                .quantity {
                    border: none;
                    outline: none;
                    text-align: center;
                    width: 100%;
                }
            }
            button {
                width: 100%;
                max-width: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 32px;
                font-weight: 200;
                &.increaseAmountCart {
                    background: #0ed178;
                    color: white;
                    border-radius: 0px 4px 4px 0px;
                    &:hover {
                        background: #0DC16F;
                    }
                }
                &.decreaseAmountCart {
                    margin-top: -2px;
                    border-radius: 4px 0px 0px 4px;
                    color: #989898;
                }
            }
        }
    }
}

.productCarouselItem .slick-track, .productCarouselItemEinstein .slick-track,.pdp-buy-together,.mainItensCarousel {
    .slick-slide {
        margin: 0 6px;
    }
    @media (max-width:576px){
        .favoriteIcon {
            max-width: 20px;
        }
        .product-tile-component-image{
            max-width: 100px;
            max-height: 100px;
        }
        .product-name-link,.quickBuyBtn{
            font-size: 11px !important;
            white-space: normal;
        }
    }
}

.notAvailable {
    margin-bottom: 0;
}

.product-tile-box, .productTileBox{
    position: relative;
}

.product-tile-box, .productTileBox{
    position: relative;
}


.qtdContainer.productCard {
    width: 100%;
    height: 100%;
    .quantity-form-tile {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid #989898;
        .quantity {
            border: none;
            outline: none;
            text-align: center;
            width: 100%;
        }

        .unit {
            display: none;
        }
    }
    .is__loading:before {
        top: 25%;
        left: calc(50% - 10px) !important;
    }
    button {
        width: 100%;
        max-width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 32px;
        font-weight: 200;

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        color: inherit;
        font: inherit;
        text-align: inherit;
        cursor: pointer;
        outline: none; 
        
        &.increaseAmountCart {
            background: #0ed178;
            color: white;
            border-radius: 0px 4px 4px 0px;
            &:hover {
                background: #0DC16F;
            }
        }
        &.decreaseAmountCart {
            border-radius: 4px 0px 0px 4px;
            color: #989898;
        }
    }
}

.quickBuyBtn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #0ED178;
    border-radius: 5px;
    background: #0ED178;
    color: #FFF;
    text-align: center;
    line-height: normal;
    white-space: nowrap;
    padding: 10px;
}

.quickBuyBtn:hover {
    background: #0DC16F;
    border: 1px solid #0DC16F;
}

a#quickShopButton{
    text-decoration: none;
}
.quantityAndShop{
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 22.8px
}

.quantityInput__control {
    border: 0.5px solid #000;
    border-radius: 5px;
    height: 46px;
}

.quantityInput {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .quantityInput__control {
        display: flex;
    }


    & button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 0 5px;


        color: #000;
    }

    & input {
        border: none;
        outline: none;
        font-weight: 600;
        width: 1ch;
        padding: 0;
        text-align: center;
    }
    & input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}


.product-tile-pd, .product, .product-detail {
    .product-unavailable-container{
        display: flex;
        gap: 6px;
        flex-direction: column;

        .product-unavailable-wrapper {
            background-color: #f7f7f7;
            border-radius: 5px;
            margin: 0;
        }

        button.quickBuyBtn.buttonUnavaliableModal {
            background: #23346C;
            border-color: #0f1b42;
            color: #FFF !important;
            font-size: 12px;
            padding: 4.5px;
            width: 100%;
            white-space: normal;
            text-align: center;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            min-height: 28px;
            @media (max-width:992px){
                font-size: 11px;
            }
        }
    }
    .product-unavailable-msg {
        font-size: 10px;
        font-weight: 700;
    }
    .unavailable-button-wrapper {
        display: flex;
        justify-content: center;
    }
}

div#unavailable-modal {
    z-index: 99999999;
    
    p.modal-title {
        margin-top: 18px;
    }

    button.quickBuyBtn.buttonUnavaliableModal.button-quarternary {
        background: #23346C;
        border-color: #0f1b42;
        color: #FFF !important;
    }
    
    .form-unavailable {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
        padding: 0.5px;
    }
    
    .unavailable-input {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        height: 48px;
        width: 100%;
        margin: 8px 0px;
        color: #7F858D;
        padding-left: 16px;
        border: 1px solid #010101;
        border-radius: 4px;
    }
}

@media (max-width: 835px){

    .product-tile {
        width: 247px;
        height: 420px;
        margin: 0px;
        justify-content: space-between !important;
        .image-container {
            display: flex;
            justify-content: center;
        }
        .tile-body .pdp-link h3 {
            color: #000;
            font-family: "Open Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 133.682%; /* 16.042px */
            margin-bottom: 0px;
        }
        .quantityAndShop {
            margin-top: 45.8px;
            .quickBuyBtn {
                padding: 8px;
            }
        }
        .quickBuyBtn {
            color: #FFF;
            text-align: center;
            font-family: "Inter" !important;
            font-style: normal;
            line-height: 109.523%;
        }
        .productTileBox {
            .product-tile-body {
                figcaption {
                    width: 100%;
                }
                .product-name-link {
                    margin-bottom: 28px;
                    color: #000;
                    font-family: "Open Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 133.182%;

                }
            }
        }
    }
}