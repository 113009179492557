.modal-content.modal-unavailableProduts {
    position: fixed;
    max-width: 570px;
    padding: 24px;
    margin: 0 auto;
    gap: 24px;
    left: 0;
    right: 0;
    top: 20%;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 4px 0px #00000040;

    .modal-header {
        gap: 24px;
        .container-headerModal{
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap:10px;

            .modal-title {
                text-transform: uppercase;
            }
            .btn-close {
                padding: 0;
                margin: 0;
                color: #D0D0D0;
                font-weight: 400;
            }
        }
    }

    .modal-body {
        padding: 0;
        .msgWillRemoved {
            color:#CE181E;
            font-size: 12px;
        }
        .containerProduct-unavailable{
            max-height: 245px;
            overflow-y: auto;
            padding-right: 6px;
            margin-bottom: 12px;
            
            &::-webkit-scrollbar {
                background-color: #DEDEDE;
                border-radius: 5px;
                width: 5px;
            }
            &::-webkit-scrollbar-thumb{
                background-color: #00744A;
                border-radius: 5px;
            }
            .productList-unavailable{
                display: grid;
                grid-template-columns: 2fr 1fr;
                margin-top: 10px;
                align-items: center;
                padding-bottom: 10px;
                border-bottom: solid 1px #DEDEDE;
                &:last-child() {
                    border-bottom: none;
                }

                p {
                    font-size: 13px;
                }

                .productDetail{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 12px;

                    .productName {
                        display: -webkit-box;
                        /* autoprefixer: off */
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: #000000 ;
                    }
    
                    img{
                        max-width: 82px;
                        max-height: 82px;
                    }
                }

                .unavailableMsg{
                    color: #CE181E;
                }

                .changeUnit {
                    font-weight: 700;
                    b {
                        font-size: 12px;
                        color: #555555;
                        text-decoration: line-through
                    }
                }
            }     
        } 
    }
    
    .modal-footer {
        border-top: none;
        padding: 0;
        gap: 20px;

        button {
            width: 50%;
            padding: 8px;
            font-size: 14px;
            font-weight: 700;
            border-radius: 5px;
            border: solid 2px #00744A;

            &.cancel-change{
                color: #00744A;
            }

            &.agree-change{
                color: $bgLight;
                background: #00744A;
            }
        }
    }
}

@media (max-width: 768px){
    .modal-content.modal-unavailableProduts{
        .modal-header{
            .modal-body {
                p {
                    font-size: 12px;
                }
            }
        }
    }
}