.slick-arrow {
    position: absolute;
    bottom: 48%;
    border: 0;
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover{
        opacity: .75;
    }

    i {
        font-size: 5rem;
    }

    &.slick-disabled {
        color: #6A6774;
    }

    &.slick-next {
        right: -20px;
        display: inline-block;
        content: url(../images/nextIcon.svg);

        &.slick-disabled {
            content: url(../images/nextIconDisabled.svg);
        }
    }

    &.slick-prev {
        display: inline-block;
        content: url(../images/prevIcon.svg);
        left: -20px;

        &.slick-disabled {
            content: url(../images/prevIconDisabled.svg);
        }
    }
}


.slick-dots {
    padding: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
}

.slick-dots li {
    display: inline-block;
    padding: 0 4px;
}

.slick-dots li button {
    width: 7px;
    height: 13px;
    background: transparent;
    border-radius: 100%;
    font-size: 0;
    border: 1px solid #696969;

}

.slick-dots li.slick-active button {
    background: #D9D9D9;
    border: #696969;
    width: 14px;
    height: 14px;
}

.carouselContainer {
    margin: 0px 80px;
}

.brandSlider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 26px;
}

button:focus {
    outline: 0px;
}

.categoriesGridCarouselMobile {
    margin: 0 40px;
  
    .slick-arrow {
        &.slick-next {
            right: -40px;
        }
  
        &.slick-prev {
            left: -40px;
        }
    }
}

@media (max-width:834px) {
    .categoriesGridCarouselMobile {
        margin: 0;

        .slick-arrow {
            &.slick-next {
                right: 5px;
            }

            &.slick-prev {
                left: 5px;
            }
        }
    }

}


@media (max-width:430px) {
    .productCarouselItemEinstein,
    .categoriesGridCarouselMobile {
        margin: 0;

        /* Oculta todos os dots */
        .slick-dots li {
            display: none;
        }

        /* Mostra apenas o primeiro dot */
        .slick-dots li:first-child {
            display: block;
        }

        /* Mostra apenas o dot do meio (se houver um número ímpar de dots) */
        .slick-dots li:nth-child(3) {
            display: block;
        }

        /* Mostra apenas o último dot */
        .slick-dots li:last-child {
            display: block;
        }

        .slick-dots {
            bottom: -80px;
            width: 100%;
            display: flex !important;
            justify-content: center;

            li {

                button {
                    border: 1px solid #696969;
                    width: 14px;
                    height: 14px;
                }

                li.slick-active button {
                    border: 1px solid #696969;
                    background-color: #696969;

                }
            }

        }

        .slick-arrow {
            &.slick-next {
                right: 20px;
                bottom: -50px;
                width: 40px;

            }

            &.slick-prev {
                left: 250px;
                bottom: -50px;
                width: 40px;

            }
        }
    }

    .categoriesGridCarouselMobile {
       margin-bottom: 85px;
    }
}

@media (max-width:376px) {
    .productCarouselItemEinstein,
    .categoriesGridCarouselMobile {
        .slick-arrow {
            &.slick-next {
                right: 20px;
                bottom: -50px;
                width: 35px;

            }

            &.slick-prev {
                left: 235px;
                bottom: -50px;
                width: 35px;

            }
        }
    }
}

@media (max-width:358px) {
    .productCarouselItemEinstein,
    .categoriesGridCarouselMobile {
        .slick-arrow {
            &.slick-next {
                right: 20px;
                bottom: -50px;
                width: 35px;

            }

            &.slick-prev {
                left: 215px;
                bottom: -50px;
                width: 35px;

            }
        }
    }
}

@media (max-width:358px) {
    .productCarouselItemEinstein,
    .categoriesGridCarouselMobile {
        .slick-arrow {
            &.slick-next {
                right: 20px;
                bottom: -50px;
                width: 40px;

            }

            &.slick-prev {
                left: 190px;
                bottom: -50px;
                width: 40px;

            }
        }
    }
}

.categoriesGridCarouselMobile {
    .slick-slide {
        margin-right: 10px;
        width: 166px !important;
        height: 253px !important;
    }

    .slick-list.draggable {
        height: 330px;
    }
}

.experience-suzano_einsten-einsteinCarouselCategorySuzano,
 .experience-suzano_layouts-productListCarousel,
 .experience-suzano_layouts-productCarousel {
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 576px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .popularCatContainer {
        min-height: inherit !important;
    }

    .productCarouselItem.skeleton-loaderCarousel {
        display: grid;
        column-gap: 12px;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr 0 0 0 0 0;
        overflow: hidden;

        @media (max-width: 1280px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 576px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
.itemsCarousel-loader {
    display: grid;
    column-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 0 0 0 0 0;
    overflow: hidden;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.fillYourBusiness-loader {
    display: grid;
    column-gap: 12px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 0 0 0 0 0;

    @media (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

