@import "bootstrap/scss/mixins/breakpoints";
@import "components/customButtons";
@import "source/core";

.myAccountHeader-options, .requestPassword-modal {
    .icon-close{
        color: #D0D0D0 ;
        font-weight: 400;
    }
}

.login-page {
    background: #FFFFFF;
    margin-top: 0;
    height: 100%;

    .login-form-nav {
        height: 100%;
        margin-bottom: 0;

        .register-body {
            padding: 48px 60px;
            display: flex;
            gap: 24px;

            .haveAccount{
                font-size:14px;
                font-weight: 600;
                a {
                    color: #00744A;
                    text-decoration: underline;
                }
            }
            .checkbox-stateRegistration{
                display: flex;
                align-items: center;
                gap: 6px;
                margin-top: 6px;
                
                label {
                    font-size: 10px;
                    margin: 0;
                }

                input {
                    height:14px ;
                }
            }
        }

        .tab-pane {
            margin: 0 auto;
        }
        &.row{
            margin: 0;
        }
        .login .passwordField img{
            right: 15px;
        }
    }

    .tab-content {
        background: #FFFFFF;

        .logo-login {
            width: 100%;
            padding: 24px;
            background: #F6F6F6;
            display: flex;
            justify-content: center;
            box-shadow: 0px 1px 1px 0px #00000040;

            img {
                width: 126px;
            }

        }

        .pforgot-password {
            line-height: 16px;
            letter-spacing: 0em;
            margin-bottom: 23px;

            a {
                color: #23346C;
            }
        }
        .link-termsConditions{
            color: #333333;
            font-size: 14px;
            a {
                text-decoration: underline;
            }
            @media (max-width:992px){
                font-size: 12px;
            }
        }

        .loginPage-btn,.registerForm-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 100%;
            background: #0ED178;
            color: #FFFFFF;
            margin: 30px 0px 110px;
        }
        .registerForm-btn{
            border-radius: 5px;
            font-size: 16px;
            padding: 8px;
            height: auto;
            margin: 24px 0;
            @media (max-width:992px){
                margin: 12px 0;

            }
            &:hover{
                background: #087D48;
            }
        }
        .endButtons-registration{
            display: flex;
            gap: 12px;
            .js-goback-btn {
                border: solid 1px #23346c ;
                color: #23346c;
                background: #FFFFFF;

                &:hover {
                        background-color: #23346c !important;
                        color: #FFFFFF;
                }

                max-width: 30%;
            }
        }

        .formLogin-socials {
            margin-top: 65px;

            .login-oauth {
                width: 100%;
                display: flex;
                justify-content: center;
               
                gap: 15px;

                .form-group {
                    width: 50%;
                    border-radius: 5px;

                    a {
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        font-size: 12px;
                        font-weight: 700;
                    }

                    &.formLogin-facebook {
                        background: #3B5998;

                        a {
                            color: #FFFFFF;
                        }
                    }

                    &.formLogin-google {
                        border: solid 1px #696969;
                        background: #FFFFFF;

                        a {
                            color: #696969;
                        }
                    }

                }
            }
        }
    }
}


.registration-page {
    .privacy-policy {
        margin-top: 1rem;
    }
}

.equal-height .card {
    width: 100%;
}

.requestPassword-modal{
    top: 25%;
    margin: 20px;
    .request-password-title {
        font-size: 18px;
        color: #002b70;
        font-weight: 700;
        text-transform: uppercase;
    }

    .modal-content{
        padding: 12px;
        .reset-password-form{
            p {
                color: #000;
            }

            .form-group input{
                height: 40px;
                font-size: 14px;
                &::placeholder{
                    color: #D0D0D0;
                }
            }
        }
    }
    .send-email-btn button{
        width: 100%;
        font-weight: 700;
        background: #0ED178;
        color: #FFFFFF;
        text-align: center;
    }
}

.nav-account-container {
    a span{
        justify-content: center;
        align-items: center;
    }
    .button-type {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 10px;
        color: #002B70 !important;
        width: 50%;
        text-align: center;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 700 !important;
        border-bottom: 1px solid #D0D0D0 !important;
        background-color: transparent !important;
        i {
            font-size: 24px;
        }

        @media (max-width:992px){
            font-size: 14px;
            i {
                font-size: 18px;
            }
        }
        
    }
    .active {
        border-bottom: 3px solid #002B70 !important;
        background-color: transparent !important;    
    }
}
.stepRegistration{
    display: flex;
    align-items: center;
    padding: 15px;
    background: #F6F6F6;
    border-radius: 5px;
    gap: 48px;
    margin: 24px 0;
    @media (max-width:992px) {
        margin: 12px 0;
    }

    .stepBar{
        display: flex;
        align-items: center;
        gap: 6px;
        span {
            color: #B0BDD3;
            border: solid 1px #B0BDD3;
            font-size: 12px;
            width: 18px;
            height: 18px;
            background-color: transparent;
            border-radius: 50%;
            text-align: center;
            line-height: 18px;
            font-weight: 600;
        }

        .firstStep.icon-check::before {
            position: absolute;
            right: 0;
            left: 0;
            font-weight: 500;
            color: white;
        }
        &.registration.step-confirmed{
            cursor: pointer;
        }

        .firstStep.icon-check {
            color: transparent;
            position: relative;
        }

        p{
            font-size: 14px;
            font-weight: 700;
            color: #B0BDD3;
        }

        &.active {
            span{
                color: white;
                background-color: #002B70;
                border: none;
            }

            p {
                color: #002B70;
            }
        }
    }
}

.login-banner {
    display: none;
}

.resetPassword-container {

    .flex-center {
        height: 440px;
        display: flex;
        align-content: center;
    }
    .card-header {
        border: none;
        padding: 1.25rem 2.25rem;
        padding-bottom: 0px;

        h4 {
            color: #02754b;
            font-style: normal;
            line-height: 140%;
            margin-top: 0;
            margin-bottom: 0;
        }   
    }

    .card-body {
        padding: 2.25rem;
        label {
            font-size: 12px;
            color: #4a4a4a;
        }
        .buttonsPasswordReset-container {
            margin-top: 25px;

            .card {
                margin-top: 40px;
            }
            .cancel-btn {
                border-color: #0ed178;
                color: #0ed178;
                font-size: 15px;
                font-weight: 700;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    background-color: #0ed178;
                    color: white;
                }
            }
            .save-btn {
                background-color: #0ed178;
                font-size: 15px;
                font-weight: 700;
                height: 50px;
                border: none;
                &:hover {
                    background-color: #097e49;
                }
            }
        }
    }
}

//content asset register-page
.registerPage-information {
    background: #F6F6F6;
    border-radius: 5px;
    .registerPage-container{

        padding: 24px;
        .first-description{
            font-size: 24px;
            color:#333333;
            font-weight: 600;
            margin-bottom: 24px;
            line-height: 1.5;
            b {
                font-weight: 800;
                color: #002B70;
            }
        }
        ul {
            gap: 48px;
            margin-top: 24px;

            li {
                display: flex;
                align-items: center;
                gap: 24px;
                list-style-type: none;
                i {
                    font-size: 32px;
                    font-weight: 500;
                    color: #333333;
                    min-width: 45px;
                    text-align: center;
                }
                span, h2 {
                    font-size: 18px;
                    color: #002B70;
                    font-weight: 700;
                    margin-bottom: 0px;
                    line-height: 1.5;
                }
                p {
                    font-size: 15px;
                    margin: 0;
                    margin-top: 6px;
                }
            }

        }
        @media (max-width: 992px){
            padding: 12px 16px;
            .first-description {
                font-size: 16px;
            }

            ul {
                margin-top: 12px;
                gap: 24px;
                li{
                    span {
                        font-size: 14px;
                    }
                    p{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .login-page {
        .login-form-nav{
            height: max-content;
            
        .register-body {
            flex-direction: column;
            padding: 24px 16px;
        }
        }

        .tab-content {
            width: 100%;
        }
        
        .requestPassword-modal{
            .modal-content{
                padding: 15px;
            }
        }
    }
}