@import "./variables";

.icomoon,
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-copy {
  &:before {
    content: $icon-copy;
  }
}

.icon-building {
  &:before {
    content: $icon-building;
  }
}

.icon-fast-shipping {
  &:before {
    content: $icon-fast-shipping;
  }
}

.icon-quotation-list {
  &:before {
    content: $icon-quotation-list;
  }
}

.icon-shipping {
  &:before {
    content: $icon-shipping;
  }
}

.icon-tag {
  &:before {
    content: $icon-tag;
  }
}

.icon-info {
  &:before {
    content: $icon-info;
  }
}

.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}

.icon-filter-circle {
  &:before {
    content: $icon-filter-circle;
  }
}

.icon-menu-options {
  &:before {
    content: $icon-menu-options;
  }
}

.icon-sort-alpha-asc {
  &:before {
    content: $icon-sort-alpha-asc;
  }
}

.icon-sort-alpha-desc {
  &:before {
    content: $icon-sort-alpha-desc;
  }
}

.icon-sort-down {
  &:before {
    content: $icon-sort-down;
  }
}

.icon-share {
  &:before {
    content: $icon-share;
  }
}

.icon-email {
  &:before {
    content: $icon-email;
  }
}

.icon-link {
  &:before {
    content: $icon-link;
  }
}

.icon-cart {
  &:before {
    content: $icon-cart;
  }
}

.icon-hamburger-menu {
  &:before {
    content: $icon-hamburger-menu;
  }
}

.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}

.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}

.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}

.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}

.icon-file-pdf {
  &:before {
    content: $icon-file-pdf;
  }
}

.icon-file-xml {
  &:before {
    content: $icon-file-xml;
  }
}

.icon-rate {
  &:before {
    content: $icon-rate;
  }
}

.icon-seller {
  &:before {
    content: $icon-seller;
  }
}

.icon-my-location {
  &:before {
    content: $icon-my-location;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-error_outline {
  &:before {
    content: $icon-error_outline;
  }
}

.icon-face-frown {
  &:before {
    content: $icon-face-frown;
  }
}

.icon-face-smile {
  &:before {
    content: $icon-face-smile;
  }
}

.icon-favorite_border {
  &:before {
    content: $icon-favorite_border;
  }
}

.icon-favorite {
  &:before {
    content: $icon-favorite;
  }
}

.icon-list-include {
  &:before {
    content: $icon-list-include;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-visibility_off {
  &:before {
    content: $icon-visibility_off;
  }
}

.icon-visibility {
  &:before {
    content: $icon-visibility;
  }
}

.icon-more {
  &:before {
    content: $icon-more;
  }
}

.icon-info-circle-fill {
  &:before {
    content: $icon-info-circle-fill;
  }
}

.icon-boleto {
  &:before {
    content: $icon-boleto;
  }
}

.icon-clock {
  &:before {
    content: $icon-clock;
  }
}

.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}

.icon-pix {
  &:before {
    content: $icon-pix;
  }
}

.icon-change {
  &:before {
    content: $icon-change;
  }
}

.icon-check {
  &:before {
    content: $icon-check;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-facebookFilled {
  &:before {
    content: $icon-facebookFilled;
  }
}

.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}

.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}

.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}

.icon-confirmed-green {
  &:before {
    content: $icon-confirmed-green;
  }
}

.icon-delivered-gray {
  &:before {
    content: $icon-delivered-gray;
  }
}

.icon-map-location {
  &:before {
    content: $icon-map-location;
  }
}

.icon-separation-gray {
  &:before {
    content: $icon-separation-gray;
  }
}

.icon-best-reviews {
  &:before {
    content: $icon-best-reviews;
  }
}

.icon-delivery-parcel {
  &:before {
    content: $icon-delivery-parcel;
  }
}

.icon-money-bag {
  &:before {
    content: $icon-money-bag;
  }
}

.icon-trash {
  &:before {
    content: $icon-trash;
  }
}

.icon-remove-coupom {
  &:before {
    content: $icon-remove-coupom;
  }
}

.icon-footer-logo {
  &:before {
    content: $icon-footer-logo;
  }
}

.icon-profile {
  &:before {
    content: $icon-profile;
  }
}

.icon-filter {
  &:before {
    content: $icon-filter;
  }
}

.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}