.experience-suzano_layouts-sellersCarousel{
    padding-top: 24px;
    padding-bottom: 24px;

    @media (max-width: 576px) {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.seller-carousel.skeleton-loaderCarousel{
    display: grid;
    overflow: hidden;
    column-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 0 0 0 0 0;
    overflow: hidden;

    @media (max-width: 1280px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 576px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .seller-card{
        margin-bottom: 2px;
    }
}

.seller-carousel {
    .slick-list.draggable{
        padding-bottom: 2px;
    }

    .slick-slide {
       margin: 0 6px;
    }
    .slick-slide div {
        display: flex;
        justify-content: center;
        grid-row-gap: 12px;
    }
    .seller-card {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        max-width: 210px;
        background: #FFF;
        border-radius: 5px;
        height: 255px;
        padding: 24px;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

        @media (max-width: 768px) {
            max-width: 210px;
        }

        @media (max-width: 576px) {
            max-width: 125px;
            height: 180px;
            padding: 12px;
        }

        @media (max-width: 390px) {
            width: 110px !important;
            max-width: 110px;
            height: 160px;
            padding: 6px;
        }

        .seller-logo{
            height: 120px;
            overflow: hidden;
            display: flex;
            align-items: center;

            @media (max-width: 576px) {
                height: 100px;
            }
            @media (max-width: 390px) {
                height: 80px;
            }
        }
        img {
            max-width: 120px;
            max-height: 120px;

            @media (max-width: 576px) {
                max-width: 100px;
                max-height: 100px;
            }

            @media (max-width: 390px) {
                max-width: 80px;
                max-height: 80px;
            }
        }

        h3 {
            color: #333;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0px;

            @media (max-width: 576px) {
                font-size: 12px;
            }
        }
        .seller-distance {
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            align-items: baseline;

            p {
                color: #002B70;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 0px;

                @media (max-width: 576px) {
                    font-size: 10px;
                }
            }
        }
    }
}
