@import "../variables";

.container-modal {
    .modal-content {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        .modal-header{
            padding: 1rem;
            .modal-title {
                text-transform: uppercase;
                color: $brandColorDarker1;
                font-size: 1.2rem;
                font-weight: 700;
            }
        }
        .modal-body{
            padding: 1rem;
            p {
                color: $brandColorDarker1;
                font-size: 14px;
            }
        }
        .modal-footer{
            border: none;
            padding: 1rem;
        }
    }
}