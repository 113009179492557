.altenativeHeader{
    height: 99px;
    padding: 19px;
    background: $brandColorGray1;
    margin-bottom: 43px;
}

.altenativeHeaderCheckout{
    height: 50px;
    background: $brandColorGray1;

    .hidden-lg-down {
        display: inherit !important;
    }
    .hidden-sm-down{
        display: flex !important;
    }

    .brand img{
        height: 36px;
        width: auto;
    }

    .header-container,
    .navbar-header{
        height: 50px !important;
    }

    .brand {
        padding-top: 0.35rem !important;
    }

    .navbarOptions-header{
        position: absolute;
        right: 0px;
    }

    .navbar-header .user .myAccountHeader-container .myAccountHeader-options.myAccountHeader-unlogged {
        translate: 11%;
        right: 0px;

        @media (max-width: 768px) {
            translate: none;
        }
    }

    .user{
        @media (max-width: 768px) {
            right: 15px;

        }

        .user-name{
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }
}
