.skeleton-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    z-index: 1;
  }
  
  .mainCarouselLoader {
    position: relative;
    aspect-ratio: 1024 / 279;
  }
  
  .hide-carousel, .hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s;
  }

  .skeleton-mainBanner {
    // @extend .skeleton-loader;
    // position: inherit;
    .experience-suzano_assets-mainBanner {
      //visibility: hidden;
      &:nth-child(n+2){
        display: none;
      }
    }
  }

  #vueAppCheckout {
    background-color: #fff;
  }
  
  .checkout-skeleton {
    padding: 60px 0;
    .title {
      width: 370px !important;
      height: 40px !important;
      margin-bottom: 20px;
    }
  
    .cards {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .skeleton-loader {
      position: relative;
      width: 100%;
      height: 100px;
    }
  
  }

  .skeleton-loaderCarouselAttributes {
    position: inherit;
    width: inherit;
    height: inherit;
    border-radius: 5px;
    color: transparent !important;
    @extend .skeleton-loader;

    * {
      visibility: hidden;
    }
  }

  .skeleton-loaderCarousel {
  //  display: grid;
   // overflow: hidden;
   // column-gap: 40px;
  //  grid-template-columns: repeat(4, 1fr);

    .product-tile-pd {
      .product-tile-image-link,
      .product-name-link,
      .strike-through,
      .productTile__price--container .line-item-total-text,
      .product-price,
      .add-to-productlist-login-btn i,
      .product-unavailable-wrapper,
      .quantityAndShop {
        @extend .skeleton-loaderCarouselAttributes;
      }
    }

    .brandItem_component{
      @extend .skeleton-loaderCarouselAttributes;
    }

    .items-carousel-box{
      gap: 12px;
      h2,span{
        height: 100px;
        width: 100%;
        @extend .skeleton-loaderCarouselAttributes;
      }
      .items-carousel-box-img{
        @extend .skeleton-loaderCarouselAttributes;
      }
      .items-carousel-box-info{
        gap: 6px;
        .items-carousel-box-tile h3 {
          @extend .skeleton-loaderCarouselAttributes;
        }
        .items-carousel-box-price p {
          @extend .skeleton-loaderCarouselAttributes;
        }
      }
    }

    .category-round{
      .category-round-img{
        @extend .skeleton-loaderCarouselAttributes;
        border-radius: 100px;
        width: 75px;
        height: 75px;

        @media (max-width: 992px) {
            width: 70px;
            height: 70px;
        }

        @media (max-width: 768px) {
            width: 65px;
            height: 65px;
        }
      }
      h5{
        height: 10px;
        width: 100%;
        @extend .skeleton-loaderCarouselAttributes;
      }
    }

    .box-category{
      gap: 12px;
      h3{
        font-family: 'Open Sans';
        height: 20px;
        width: 100%;
        @extend .skeleton-loaderCarouselAttributes;
      }
      .box-category-img{
        @extend .skeleton-loaderCarouselAttributes;
      }
      .box-category-products .box-category-product{
        @extend .skeleton-loaderCarouselAttributes;
      }
      .box-category-show-more{
        height: 18px;
        width: 100%;
        @extend .skeleton-loaderCarouselAttributes;
      }
    }

    .seller-card {
      height: auto;
      gap: 5px;
      div, h1 {
        @extend .skeleton-loaderCarouselAttributes;
      }
    }

  }
  .skeleton-product-tile {
    box-shadow: 0 1px 1px 0 #00000040;
    border-radius: 5px;
    background: #FFF;
    padding: 6px;
    .skeleton-loader-component{
      @extend .skeleton-loaderCarouselAttributes;
    }
  }

  @keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
  }
   