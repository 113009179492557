.header-item-center{
    display:flex;

}
.menu-relative{
    position: relative;
}
.menu ul.menu-section{
    padding: 0px;
    margin: 0px;
}
.menu>ul>li{
    display:inline-block;
    line-height:3.125rem;
}
.menu>ul>li>a{
    position:relative;
    font-family:inherit;
    font-weight:700;
    font-size: 13px;
    line-height: 18px;
    border:none;
    outline:none;
    color:#121212;
    text-rendering:optimizeLegibility;
    transition:color .3s ease-in-out
}
.menu>ul>li>a.nav-link{
    padding: .8rem .5rem;
}

.menu>ul>li .menu-subs{
    position:absolute;
    //width:100%;
    height:auto;
    margin-top:1rem;
    padding:1rem 2rem;
    border:none;
    outline:none;
    z-index:500;
    opacity:0;
    visibility:hidden;
    border-radius:0 0 5px 5px;
    background:#fff;
    box-shadow:0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06);
    transition:all .5s ease-in-out
}


.menu>ul>li .menu-subs .menu-subcategory.menu-mega-left{
    position:absolute;
    height:366px;
    margin-top:0px;
    padding:1rem 2rem;
    border:none;
    outline:none;
    z-index:500;
    opacity:0;
    visibility:hidden;
    border-radius:0 0 5px 0px;
    background:#fff;
    box-shadow:none;
    &::before{
        border-left: 1px solid #EAEAEA;
        content: "";
        display: block;
        height: 325px;
        left: 10px;
        position: absolute;
        top: 18px;
        width: 1px;
        z-index: 0;

    }
}

.menu>ul>li .menu-subs>ul>li,
.menu>ul>li .menu-subs>ul>li .menu-subcategory>ul>li
{
    line-height:1
}
.menu>ul>li .menu-subs>ul>li>a,
.menu>ul>li .menu-subs>ul>li .menu-subcategory>ul>li>a{
    display:inline-block;
    font-family:inherit;
    font-size:1rem;
    font-weight:500;
    line-height:1.25;
    padding:.75rem 0;
    border:none;
    outline:none;
    color:#121212;
    text-transform:capitalize;
    text-rendering:optimizeLegibility;
    transition:all .3s ease-in-out
}
.menu>ul>li .menu-column-1,
.menu-subcategory.menu-mega-left .menu-column-1{
    min-width:10rem;
}

.menu>ul>li .menu-column-2,
.menu-subcategory.menu-mega-left .menu-column-2 {
    min-width:30rem;
}

.menu>ul>li .menu-column-3,
.menu-subcategory.menu-mega-left .menu-column-3{
    min-width:45rem;
}

.menu-subcategory.menu-mega-left .menu-column-4{
    min-width: 50rem;
}

.menu>ul>li {
    .menu-single-column{
        width: 215px;
        height: 366px;
        padding:1rem 0 1rem .5rem !important;
    }

    &:hover{
        .menu-single-column{
            border-radius: 0px 0px 0px 5px !important;
        }
    }
}


.icon-menu{margin-right: 15px;}

.menu>ul>li .menu-subs.menu-single-column .list-item{
    ul{
        padding: 0;
        list-style: none;
        li{
            font-size: 13px;
            line-height:1;
            border-radius: 5px;

            a{
                font-weight: normal;
                padding: .5rem;
                display: inline-block;
                width: 100%;
            }

            &:hover{
                text-decoration: none;
                font-weight: bold;
                background-color: #F4F4F4;
                a{
                        text-decoration: none;
                        font-weight: bold;
                }

            }
        }
    }
}

.menu-subcategory.menu-mega-left .list-item{
    ul{
        li{
            border-radius: inherit;
            font-weight: normal;
            a{
                font-weight: normal !important;
                padding: 0px !important;
                display: inherit;
                width: inherit;
            }

            &:hover{
                text-decoration: none;
                font-weight: bold !important;
                background-color: inherit !important;
                a{
                        text-decoration: none;
                        font-weight: bold !important;
                }

            }
        }
        .last-link{
            font-weight: 500 !important;
        }
    }
}

.menu>ul>li .menu-subs.menu-mega,
.menu>ul>li.menu-relative .menu-subs{
    left:50%;
    transform:translateX(-50%)
}

.menu>ul>li .menu-subs.menu-mega{
    width:100%;
}

.menu>ul>li .menu-subs .menu-subcategory.menu-mega-left{
    top:0px;
    left: 215px;
}

.menu>ul>li .menu-subs.menu-mega>.list-item>ul>li,
.menu>ul>li .menu-subcategory.menu-mega-left.list-item>ul>li{
    display:block;
    line-height:1
}
.menu>ul>li .menu-subs.menu-mega>.list-item>ul>li>a,
.menu>ul>li .menu-subcategory.menu-mega-left>.list-item>ul>li>a{
    display:inline-block;
    font-family:inherit;
    font-size:1rem;
    font-weight:500;
    line-height:1.25;
    padding:.5rem 0;
    border:none;
    outline:none;
    color:#121212;
    text-transform:capitalize;
    text-rendering:optimizeLegibility;
    transition:color .3s ease-in-out
}
.menu>ul>li .menu-subs .menu-column-1,
.menu>ul>li .menu-subs .menu-column-2,
.menu>ul>li .menu-subs .menu-column-3,
.menu>ul>li .menu-subs .menu-column-4{
    display:flex;
   // flex-wrap:wrap;
    width:100%;
    padding-bottom: 1.25rem ;
    justify-content: space-between;
}
.menu>ul>li .menu-subs .menu-column-4 >.list-item,
.menu-subcategory.menu-mega-left .menu-column-4 >.list-item{
    flex:0 0 23%;
    ul{
        padding: 0;
        list-style: none;
        li{
            font-size: 13px;
            line-height:1;
            padding: .5rem .5rem .5rem 0;

            a{
                border-radius: 5px;
                font-weight: normal;
                &:hover{
                    text-decoration: none;
                    font-weight: bold;
                }
            }
        }
    }
    .last-link{
        font-weight: 500;
    }
}

.menu>ul>li .menu-subs .menu-column-1 >.list-item,
.menu>ul>li .menu-subs .menu-column-2 >.list-item,
.menu>ul>li .menu-subs .menu-column-3 >.list-item{
    ul{
        padding: 0;
        list-style: none;
        li{
            font-size: 13px;
            line-height:1;
            padding: .5rem .5rem .5rem 0;

            a{
                border-radius: 5px;
                font-weight: normal;
                &:hover{
                    text-decoration: none;
                    font-weight: bold;
                }
            }
        }
    }
    .last-link{
        font-weight: 500;
    }
}
.menu>ul>li .menu-subs .menu-column-2 >.list-item,
.menu-subcategory.menu-mega-left .menu-column-2 >.list-item{
    flex:0 50%;
}

.menu>ul>li .menu-subs .menu-column-3 >.list-item,
.menu-subcategory.menu-mega-left .menu-column-3 >.list-item{
    flex:0 0 25%;
}
.menu>ul>li .menu-subs .title,
.menu-subcateogry .title{
    font-family:inherit;
    font-size:13px;
    font-weight:700;
    line-height:1;
    padding:.75rem 0;
    border:none;
    outline:none;
    color:#000;
    text-rendering:optimizeLegibility;
    transition:all .3s ease-in-out;
    display: block;
    margin-bottom: .5rem;
}

.menu>ul>li .menu-subs .menu-column-4>.list-item img.responsive{
    max-width:100%;
    width:100%;
    height:auto;
    margin-top:.75rem;
    object-fit:cover;
    vertical-align:middle
}
.menu>ul>li .menu-subs.menu-mega >.list-item>ul>li>a:hover,.menu>ul>li .menu-subs>ul>li>a:hover{
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    color:#000
}
.header-item-right a:hover,.menu>ul>li:hover>a{
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    color:#000
}
@media only screen and (min-width:768px){
    .menu>ul>li.menu-item-has-children:hover .menu-subs{
        margin-top:0;
        opacity:1;
        visibility:visible
    }

    .menu>ul>li.menu-item-has-children:hover .menu-subs.menu-single-column>.list-item ul li.menu-item-has-subcategory:hover .menu-subcategory.menu-mega-left{
        margin-top:0;
        opacity:1;
        visibility:visible
    }

}

@media (max-width:1200px){
    .menu-item-desktop{
        display: none !important;
    }
    .menu-subs {

        .menu-column-1,
        .menu-column-2,
        .menu-column-3,
        .menu-column-4 {
            gap: 20px;
            min-width: max-content !important;

            .list-item {
                white-space: nowrap;
                flex: 0 !important;
            }
        }
    }
}

@media(max-width:992px) {
    .menu-column-4 {
        .menu-items {
            display: none;
        }
    }
}