// Brand Colors
$brandColor1: #006EB7;
$brandColor2: #0ED178;
$brandColorDarker1: #002B70;
$brandColorDarker2: #00744A;
$brandColorDarker3: #23346C;
$brandColorDarker4: #02754B;
$brandColorGray1: #EAEAEA;
$brandColorOffWhite: #FEFAE0;
$disabledColor: #e9ecef;

// Background Colors
$bgBlack: #000;
$bgDarker: #383838;
$bgDark: #383838;
$bgLight: #F4F4F4;
$bgLighter: #ffffff;
$bgLight2:#F5F5F5;
$bgLighter3:#D9D9D9;
$bgFooterCategory: #383838;
$bgOverlay: rgba(0,0,0, .2%);

// Button Colors
$btnPrimaryColor: #0ED178;
$btnSecondaryColor: #FFFFFF;
$btnTertiaryColor: #454545;

//Button Hovers
$btnPrimaryColorHover: #0D734B;
$btnSecondaryColorHover: #000000;
$btnTertiaryColorHover: #FFFFFF;

$labelColorGray: #4A4A4A;

// Border Colors
$borderColor: #000000;
$borderColor2:#BDBDBD;
$inputBorderColor: #999999;

// Status Colors
$successColor: #00d988;
$errorColor: #dd0000;

// Gradient
$bgGradient1: linear-gradient(180deg, #002B70 0%, #006EB7 100%);
$bgGradient2: linear-gradient(180deg, #00744A 0%, #0ED178 100%);


// Product Tags
$superDesconto:#0047FF;
$compreEGanhe:#0047FF;
$lancamento:#41A017;
$promocao:#41A017;
$diaDoRevendedor:#FF9F00;
$superOferta:#09215f;
$sale:#FF0F00;
$bigSale:#FF0F00;
$useCupomEuQuero:#FF0F00;
$saldao:#BA009C;
$novidade:#00C3AB;
$blackFriday: #383838;
$defaultTagColor: #5B5853;
$alertMessage: #FF4949;
