.categoriesGrid {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-gap: 24px;
}

.popularCatHeading {
  display: flex;

  h2 {
    color: #03267F;
    font-size: 23px;
    font-family: "Open Sans" !important;
    font-weight: 700;
    margin-bottom: 24px;
  }
}

.sellerCarouselContainer {
  min-height: 200px;
}

.popularCategory {
  width: 100%;
}

@media (max-width: 991px) {
  .popularCatHeading {

    h2 {
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .categoriesGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .categoriesGrid {
    grid-template-columns: repeat(2, 403px);
  }
}

@media (min-width: 1200px) {
  .categoriesGrid {
    grid-template-columns: repeat(3, 403px);
  }
}

@media (max-width: 768px) {
  .categoriesGrid {
    grid-template-columns: repeat(2, 350px);

    .card-horizontal {
      width: 350px;
      margin-bottom: 13px;
    }
  }

  .popularCategory {
    .card-horizontal {
      height: 319px;
      flex-direction: column;

      .img-square-wrapper {
        padding: 0px;
        padding: 20px;
      }

      .cardText {
        font-family: "Open Sans";
        font-style: normal;
        margin-bottom: 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .card-title {
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
        }

        .card.text {
          color: #7b7b7b;
          font-size: 12px;
          font-weight: 300;
          line-height: 130.182%;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .popularCatHeading {

    h2 {
      font-size: 1.2rem;
      margin-bottom: 12px;
    }
  }
}
