// Main font family
$fontFamily: "Open Sans", sans-serif;

// Font weights
$extraBold: 800;
$bold: 700;
$semiBold: 600;
$regular: 400;
$light: 300;

// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

.bold {
    font-weight: $bold !important;
}

.semi-bold{
    font-weight: $semiBold !important;
}

//Headings
.sz-heading--largest,
h1 {
    font-family: $fontFamily;
    font-weight: 700;
    font-size: 30px;

    @media (max-width: $lg) {
        font-size: 21px;
    }
}

.sz-heading--large,
h2 {
    font-family: $fontFamily;
    font-weight: 700;
    font-size: 23px;

    @media (max-width: $lg) {
        font-size: 18px;
    }
}

.sz-heading--medium,
h3 {
    font-family: $fontFamily;
    font-size: 18px;
    font-weight: 700;
}

.sz-heading--small,
h4 {
    font-family: $fontFamily;
    font-size: 13px;
    font-weight: 700;
}

.sz-heading--smallest,
h5 {
    font-family: $fontFamily;
    font-size: 12px;
    font-weight: 600;

    @media (max-width: $lg) {
        font-size: 10px;
    }
}

// Body/ Text in general/Paragraph

.sz-text--largest {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 20px;
}

.sz-text--largest-resp {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 20px;

    @media (max-width: $lg) {
        font-size: 16px;
    }
}

.sz-text--large {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 16px;
}

.sz-text--large-resp {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 16px;

    @media (max-width: $lg) {
        font-size: 12px;
    }
}

.sz-text--medium {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 14px;
}

.sz-text--default,
p {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 13px;
}

.sz-text--small {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 12px;
}

.sz-text--small-resp {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 12px;

    @media(max-width: 992px) {
        font-size: 10px;
    }
}

.sz-text--smallest {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 8px;
}

.text-decoratation-none {
    text-decoration: none !important;
}

//icons
.sz-icon--large {
    font-size: 20px;
    font-weight: 400;
}

.sz-icon--medium {
    font-size: 18px;
    font-weight: 400;
}

.sz-icon--small {
    font-size: 14px;
    font-weight: 400;
}

.sz-icon--smallest-bold {
    font-size: 12px;
    font-weight: 700;
}