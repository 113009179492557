
footer {

    ul,
    li {
        font-size: 14px;
        font-family: 'Open Sans';
        font-weight: 400;
        line-height: 19px;

        a {
            color: $white !important;
            text-decoration: none;
        }
    }

    h3,
    .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-align: start;
        color: $white;
    }

    .title {
        color: #fff !important;
        &::after {
            content: none !important;
            display: none !important;
        }
    }

    @media (max-width:1200px) {
        .footer-box {
            ul li {
                margin-bottom: 6px;
            }

            &.active {
                button i {
                    transform: rotate(180deg);
                }
            }

            .title {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 24px;
                box-shadow: none !important;
            }
        }

    }
}

.footer-firstBlock {
    background: linear-gradient(to bottom, transparent 80%, #006EB7 20%);
    margin-top: 48px;

    .container {
        border-radius: 10px;
        background: #EAEAEA;
        box-shadow: 0px 2px 2px 0px #00000040;
    }

    h3 {
        margin-bottom: 24px
    }

    h3,
    p,
    a,
    i {
        color: #333333 !important;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 24px;
    }

    .footer-service-channels {
        h3 {
            margin-bottom: 8px;
        }

        ul {
            display: flex;
            gap: 12px;
            flex-direction: column;
        }

        li a {
            display: flex;
            align-items: center;
            gap: 12px;

            i {
                font-size: 18px;
            }
        }
    }

    .footer-social-media {
        ul {
            display: flex;
            gap: 24px;

            li i {
                font-size: 32px;
            }
            a {
                text-decoration: none;
            }
        }
        @media (max-width:992px){
            ul{
                gap: 18px;
            }
        }

    }

    .footer-payment-methods {
        .footer-payment-options {
            display: flex;
            gap: 12px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    @media(max-width:992px) {
        padding: 0 24px;

        h3 {
            margin-bottom: 12px;
        }
    }
}

.footer-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: start;
    padding: 24px;

    @media(max-width:992px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            "item1 item1"
            "item2 item3"
            "item4 item4";
        row-gap: 24px;
        column-gap: 12px;

        .firstBlock-item1 {
            grid-area: item1;
        }

        .firstBlock-item2 {
            grid-area: item2;
        }

        .firstBlock-item3 {
            grid-area: item3;
            justify-self: end;
        }

        .firstBlock-item4 {
            grid-area: item4;
        }
    }
}

.footer-secondBlock {
    background: #006eb7;

    h3 {
        margin-bottom: 12px;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 0;
    }

    .footer-grid {
        @media (max-width:992px) {
            display: flex;
            flex-direction: column;
            padding: 24px 35px;
            row-gap: 10px;
        }
    }
}

.footer-thirdBlock {
    background: $brandColorDarker1;

    .footer-copyright {
        padding: 15px;
    }

    .content-asset {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .postscript {
        display: flex;
        gap: 24px;
        font-size: 12px;
        font-weight: 400;
        color: $white;
        margin: 0;
        align-items: center;

        a i {
            color: #FFF;
            font-size: 40px;
        }
        a {
            text-decoration: none;
        }
    }

    ul {
        display: flex;
        gap: 20px;
    }

    @media(max-width:992px) {
        .footer-copyright {
            padding: 12px;
            padding-bottom: 24px;
        }

        .content-asset {
            align-items: flex-start;
            flex-direction: column;
            gap: 24px;
        }

        .postscript {
            p {
                font-size: 10px;
            }

            a i {
                font-size: 36px;
            }
        }

        ul {
            flex-direction: column;
        }
    }
}

.footer-container,
footer {
    background: transparent;
}

footer .content li {
    height: auto;
}

.divCookiePoliticaPrivacidade {
    background-color: rgba(52, 58, 64, 0.85);

    .text-cookies {
        color: #fff;
        padding-top: 15px;

        a {
            text-decoration: underline;
            color: #fff;
        }

        a:hover {
            color: #fff;
        }
    }

    .cookie-accept {
        text-align: center;
        align-self: center;
    }

    .accept-consent-btn {
        color: #fff;
        background-color: #00744a;
        border: 0;
        font-size: 14px;
        padding: 6px 12px;
    }
}