.wishlist-landing__wrapper {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
    }
    .wishlist-page__col {
       // height: max-content;
        background: #ffffff;
        border: 0.5px solid #d0d0d0;
        border-radius: 5px;
        padding: 32px;

        & > label {
            display: none; //hides label on mobile list selection
        }

        h3{
            margin-bottom: 18px;
        }
        h4{
            font-size: .9rem;
        }

        .plProductCard-itemLabel {
            font-size: 13px;
            align-content: center;
            p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                /* autoprefixer: off */
                overflow: hidden;
                font-weight: bold;
                line-height: 1.4;
                margin: 0;
            }
        }

        .selected-productlist .plProductCard__col .plProductCard-itemQuant {
            width: 100%;
            .qtdContainer{
                .quantity-label{
                    display: none;
                }

                .quantity-form{
                    border-radius: 5px;
                    border: 1px solid #989898;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    button {
                        width: 100%;
                        max-width: 48px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        font-size: 32px;
                        font-weight: 200;
                        font-size: 20px;
                        appearance: none;
                        border: none;
                        margin: 0;
                        padding: 0;
                    }

                    button.decreaseAmountProductList {
                        border-radius: 4px 0 0 4px;
                        color: #989898;
                    }

                    button.increaseAmountProductList {
                        background: #0ed178;
                        color: #fff;
                        border-radius: 0 4px 4px 0;
                    }

                    .quantity-form-tile .quantity {
                        text-align: center;
                        width: 100%;
                        position: relative;
                        left: -5px;
                        top: 0;
                        font-size: 13px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.pl-unit-price-label{
    display: none;
    font-size: .813em;
    color: #000;
}

.newList__wrapper {
    .addlist-productlist-btn {
        background: none;
        border: none;
        font-weight: 600;
        cursor: pointer;
        padding: 0px;
        width: 100%;
        text-align: left;
    }
    .formFirstRow {
        gap: 20px;
    }
    .newList__nameInput {
        width: 100%;

        & input {
            padding: 20px;
        }
    }
    .newList__button {
        width: 100%;
        button{
            text-transform: inherit;
        }
    }
}

#confirmDeleteListModal {
    .modal-header {
        color: $alertMessage;
    }
    .modal-dialog {
        top: 30%;
    }
    .modal-content {
        padding: 40px 20px;
    }
    .modal-body {
        display: flex;
        gap: 10px;
        font-size: 16px;
        font-weight: 400;
        button {
            flex: 1;
        }
    }
}

#addListModal,
#editListModal,
#moveProductModal {
    .modal-dialog {
        top: 15%;
    }

    .modal-content {
        border-radius: 5px;
        padding: 1rem 1.5rem;
    }

    .modal-header {
        position: relative;
        text-transform: uppercase;
        h3 {
            font-size: 25px;
        }
        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
    .modal-body {
        padding: 0;
    }
}

#moveProductModal .buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button{
        width: 100%;
    }
}

#editListModal.editList {
    .modal-dialog {
        top: 40%;
        transform: translateY(-50%);
        max-width: 600px;
    }

    .modal-body {
        .editlist-buttons-wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}

.edit-list-btn {
    background: none;
    border: none;
    outline: none;
    font-size: 12px;
    color: #8A8A8A;
    padding: 0px;

    i{
        margin-right: 5px;
    }
}

.listItem__wrapper {
    display: flex;
    justify-content: space-between;

    p {
        flex: 1;
        padding: 20px 0;
        cursor: pointer;
    }
    input {
        display: none;
    }
    label {
        cursor: pointer;
    }
    .listRadioInput.active ~ label {
        font-weight: bold;
        color: $brandColor2;
    }
}
.add-product-to-product-list-btn {
    display: none;
}

.add-product-to-product-list-btn.enabled {
    display: block;
}
.addToWishlistModal {
    z-index: 1050 !important;

    .modal-dialog {
        top: 20%;
    }
    .modal-content {
        padding: 20px;
    }
    .modal-actions {
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: start;
        .create-list-btn {
            border: none;
            background: none;
            padding: 15px 0px;
            align-items: center;
            display: inline-flex;
            gap: 8px;
        }
        #productListBtn {
            width: 100%;
        }
    }

    label{
        color: #212529
    }
    button{
        text-transform: inherit;
    }
}


.productlist-itemsCard__header {
    .product-info-header {
        display: grid;
        grid-template-columns: 1fr 6fr;
        padding: 0 20px;
        h1 {
            font-size: 14px;
            font-weight: bold;
        }
        p {
            font-size: 12px;
        }

        .col2 {
            display: grid;
            grid-template-columns: 2.9fr 1fr 1fr 1fr 1fr;
        }
        .center {
            justify-self: center;
        }
    }
}

.productlist-itemCard {
    display: grid !important;
    grid-template-columns: 0.3fr 6fr;
    
    .productlist-itemImg {
        max-width: 75px;
        margin-right: 13px;
        margin-bottom: 0;
        height: 75px;
        object-fit: contain;
    }
    .center {
        justify-self: center;
        align-content: center;
    }
    .itemInformation {
        display: grid;
        grid-template-columns: 0.5fr 2fr 0.8fr 0.75fr 0.8fr 0.2fr;
    }
    .item-check-add-to-cart {
        padding: 0;
        .check {
            top: 0;
        }
    }
    .buttonsWrapper {
        margin-top: 2px;
    }
    .qtdContainer {
        margin-top: 2px;

        .productlistItemQuant {
            max-width: 35px;
            text-align: center;
            border: none;
        }
        .quantity-form {
            height: 32px;
        }
        .decreaseAmountProductList,
        .increaseAmountProductList {
            background: none;
            border: none;
        }
    }
    .plProductCard-itemPrice {
        align-content: center;
        .price {
            text-align: center;

            .strike-through {
                display: none;
            }
            del {
                font-size: 12px;
                .strike-through {
                    margin: 0;
                }
            }
            .sales {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
    .plProductCard-subTotal {
        span {
            line-height: 1;
        }
    }
    .plProductCard-controllers {
        .buttonsWrapper {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            gap: 5px;

            button {
                border: none;
                background: none;
                padding: 0;
            }
        }
    }
}

.productCardFooter__productList {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .addToCart {
        display: none;
    }
    p{
        margin-left: 38px;
    }
}

@media (max-width: $lg) {
    .productlist-itemsCard__header {
        display: none;
    }

    .productlist-itemCard {
        padding: 1.5rem 0;

        .itemInformation {
            grid-template-columns: repeat(auto-fit, minmax(78px, 1fr));
            gap: 0 0.5rem;

            .plProductCard-itemLabel {
                grid-column: 1 / -1;
                grid-area: 5 / 2 / 2 / -1;
                line-height: 1;
                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    /* autoprefixer: off */
                    overflow: hidden;
                    font-weight: bold;
                    line-height: 1.4;
                }
            }

            .plProductCard-itemPrice {
                grid-column: 1 / -1;
                justify-self: flex-start;
                margin-top: 40%;
                grid-area: 5 / 2 / 2 / -1;

                .price{
                    flex-direction: row !important;
                    justify-self: flex-start;
                    display: flex;
                    align-items: baseline;
                    gap: 5px;

                    .pl-unit-price-label{
                        display: block;
                    }
                    .price-content-tile span{
                        font-weight: bold;
                        font-size: 16px;
                    }
                }
            }

            .plProductCard-subTotal{
                display: none !important;
                order: 1;
            }

            .plProductCard-itemQuant {
                justify-self: flex-end;
                grid-column: 1 / -1;
                margin-top: 16px;
            }

            .plProductCard-itemQuant{
                order: 2;
            }

            .plProductCard-controllers {
                grid-area: 1 / 1 / 2 / -1;
                margin-bottom: 0.75rem;
                width: 100%;

                .buttonsWrapper {
                    justify-content: flex-end;
                }
            }
        }
    }
}

@media (max-width: $md) {
    .productCardFooter__productList {
        flex-direction: column;

        .addToCart {
            button {
                width: 100%;
            }
        }
    }
}
