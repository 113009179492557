/*@import "menu";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
    font-family: "Open Sans";
}
*/
@import '../login.scss';
$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

@media (min-width: 992px) {
    .header-container {
        height: 110px;
    }
}

.header {
    position: relative;
}

.stickyHeader{
    width: 100%;
    background-color: #eaeaea;
}

.header.mainHeader {
    background-color: #eaeaea;
}

.stickyHeader.fixed, .header.container.mainHeader.fixed {
    margin-top: 0;
    position: fixed !important;
    top: 0;
    z-index: 1002;
  }

.header-whatsapp {
    background-color: #00744a;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 2px;
    }

    a:hover {
        color: #ffffff;
        text-decoration: none;
    }

    img {
        width: 22px;
    }

    i {
        font-size: 18px;
    }
}

.mainHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //margin-top: 15px;

    .navbar-toggler {
        font-size: 16px;
    }
}

.navbarOptions-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
}

.btn-loginModal {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 8px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 5px;
    margin-top: 12px;
}

.navbar-header{
    height: 4em;

    /* 70/16 */
    .myListsWrapper {
        display: flex;
        gap: 25px;

        @media (max-width: 430px) {
            gap: 8px;
        }

        .searchSuggestions__badge {
            display: flex;
            .icon-search {
                display: flex;
                color: #002b70;
                font-size: 22px;
                flex-direction: column;
                justify-content: center;
            }
        }

    }

    .user,
    .country-selector,
    .search,
    .minicart {
        display: inline-block;
        margin: 0;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        line-height: 16px;
        height: auto;
        color: #000;
    }

    .navbar-toggler {
        font-size: 1.6em;
        width: auto;
    }

    .user-message {
        display: flex;
        align-items: flex-end;
        gap: 8px;
    }

    .myList {
        line-height: 16px;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 4px;

        span {
            color: #002b70;
            font-size: 12px;
        }
        i {
            font-size: 24px;
            @media(max-width: 992px) {
                font-size: 22px;
            }
            color: #002b70;
        }
    }

    .user {
        position: relative;
        z-index: 1;

        .dropdown-toggle {
            &::after {
                content: none;
            }
        }

        .user-unlogged{
            border: none;
            background: transparent;
        }
        .userName-header{
            max-width: 110px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        a, .user-unlogged {
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 4px;

            span {
                color: #002b70;
                font-size: 12px;
            }

            i.icon-profile {
                font-size: 24px;
                @media(max-width: 992px) {
                    font-size: 22px;
                }
                color: #002b70;
            }
        }

        .popover {
            position: absolute;
            display: none;
            padding: 1em;
            top: 85%;
            left: 0;

            a {
                white-space: nowrap;
                margin-bottom: 0.5em;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }

        .myAccountHeader-container {
            background: transparent;
            border: none;
            padding-top: 30px;
            translate: -15%;

            .tooltipIndicator {
                background: #ffffff;
                z-index: -1;
                width: 10px;
                top: 16px;
                left: 42%;
                border-radius: 0;
                transform: rotate(45deg);
                border: none;
            }

            .myAccountHeader-options {
                background: #ffffff;
                border: none;
                box-shadow: 0 1px 2px 0 #00000033;
                padding: 20px 30px;
                border-radius: 5px;

                &.myAccountHeader-unlogged {
                    position: absolute;
                    top: 5px;
                    translate: 50%;
                    right: 0;
                    width: 315px;
                    padding: 12px;

                    .containerLogin {
                        display: flex;
                        font-size: 12px;
                        font-weight: 400;
                        color: $bgLighter !important;
                        margin: 0;
                        text-decoration: none;
                        flex-direction: column;
                        width: 100%;
                        min-width: 180px;
                        border-radius: 5px;

                        .form-group {
                            input{
                                height: 40px;
                                font-size: 14px;
                                &::placeholder{
                                    color: #D0D0D0;
                                }
                            }

                            img{
                                right: 12px;
                                top:35px;
                            }
                        }
                        .title-modalLogin{
                            color: $brandColorDarker1;
                            margin-bottom:0
                        }

                        .login-option {
                            @extend .btn-loginModal;
                            color: $bgLighter;
                            background: #0ed178;

                            &:focus {
                                background-color: #0ed178 !important;
                            }
                        }
                        .pforgot-password a{
                            color: #808080;
                            text-decoration: underline;
                        }
                        .oauth-google {
                            @extend .btn-loginModal;
                            gap: 12px;
                            font-weight: 400;
                            color: #808080;
                            border: solid 1px #D0D0D0 ;
                            margin-bottom: 12px;
                        }

                        .register-option {
                            @extend .btn-loginModal;
                            border: solid 1px #23346c ;
                            color: #23346c;
                            background: $bgLighter;

                            &:focus {
                                background-color: #23346c !important;
                                color: $bgLighter;
                            }
                        }

                        .login-divider{
                            display: flex;
                            align-items: center;
                            text-align: center;
                            width: 100%;
                        
                            span {
                                margin: 0 12px;
                                white-space: nowrap;
                                color: #D0D0D0 ;
                            }
                        
                            &::before,
                            &::after {
                                content: '';
                                flex-grow: 1;
                                background: #D0D0D0;
                                height: 1px;
                                margin: 0 10px;
                            }
                        }
                    }
                }

                &.myAccountHeader-logged ul {
                    gap: 10px;

                    li {
                        font-size: 14px;

                        a {
                            display: flex;
                            align-items: center;
                            gap: 14px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #4a4a4a !important;
                            text-decoration: none;

                            &*::before {
                                line-height: 0;
                            }

                            img {
                                filter: grayscale(100%) brightness(142%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.navbar-header .user .myAccountHeader-container .myAccountHeader-options.myAccountHeader-logged {
    padding: 0;
    ul {
        gap: 0px;
    }
    li {
        cursor: pointer;
        padding: 10px 15px;
        width: 100%;
        &:hover {
            background: #f7f7f7 !important;
        }
    }
}

.brand {
    position: static;
    display: block;
    text-align: center;
    max-width: 120px;

    h1{
        max-width: 120px;
        overflow: hidden;
        max-height: 48px;
        padding: 0px;
        margin-bottom: 0px;
        font-weight: 700;
        font-size: 30px;
    }

    img {
        width: auto;
        height: 48px;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 0;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        width: 6em;
        padding-top: 0;
        margin-left: 0;
    }
}

.main-menu {
    background-color: #eaeaea;
    z-index: 2147483648; //MAX Z-INDEX

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }
}

.header-banner {
    background-color: $darker-gray;
    text-align: center;
    color: $white;

    .close-button {
        width: 1.5em + $banner-padding * 2;

        .close {
            opacity: 1;
            color: $white;
            width: 100%;
            height: 100%;
            background-color: #{var(--skin-primary-color-1)};
        }
    }

    .content {
        margin-right: 1.5em;
        padding-top: $banner-padding;
        padding-bottom: $banner-padding;
    }
}

.minicart {
    margin-top: 0.1875em;
    /* 3/16 */
    vertical-align: top;
    cursor: pointer;

    .minicart-icon {
        font-size: 1.5em;
    }

    a.minicart-link {
        &:hover {
            text-decoration: none;
        }
    }

    i {
        font-size: 36px;
        @media(max-width: 992px) {
            font-size: 32px;
        }
        color: #002b70;
    }

    .minicart-quantity {
        background-color: #0ed178;
        border-radius: 50%;
        line-height: normal;
        display: inline-flex;
        font-size: 14px;
        position: absolute;
        top: -0.5em;
        right: -0.5em;
        left: inherit !important;
        color: #fff;
        width: auto;
        min-width: 17px;
        align-items: center;
        justify-content: center;

        &.hide {
            display: none;
        }

        @media (max-width: 430px) {
            top: -0.2375em;
            left: -0.6375em;
        }
    }
}

a.normal {
    color: #{var(--skin-primary-color-1)};
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}


#recentlyviewed {
    font-family: Open Sans!important;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #000!important;
    cursor: pointer;
}

.repeatOrder-container, .productsRecentlyViewed-container {
    background-color: #fff!important;
    border-radius: 5px!important;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

    position: absolute !important;
    top: 100% !important;
    z-index: 1050!important;
    left: -214px !important;

    display: none!important;
    transition: all 0.2s ease-in-out !important;

    min-width: 380px !important;
    padding: 0!important;
    border: 0 !important;
    &.show {
        display: unset!important;
    }

    .arrow-top {
        margin: 0 auto;
        width: 0;
        height: 0;
        border-right: 17px solid transparent;
        border-top: 20px solid transparent;
        border-left: 17px solid transparent;
        border-bottom: 20px solid #fff;
        position: absolute;
        top: -37px;
        right: 11px;
        transition: all .2s ease-in-out;
        z-index: 0;
    }

    p {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.28px;
        margin-bottom: 0;
        padding: 24px;
    }

    .btn-popover {
        height: 56px;
        width: 100%;
        border-radius: 5px;
        background-color: #00744A;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        display: grid;
        align-items: center;
    }
    .product-wrapper-header {
        width: 100%;
        font-weight: 300;
        overflow: auto;
        padding: 15px 24px !important;
        margin: 0;
        flex: 1 1 auto !important;
        max-height: 354px;

        .productTileBox {
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            justify-content: flex-start;
            margin: unset;
            height: fit-content;

        }

        .product-tile-pd {
            max-width: 100% !important;
            height: fit-content;
            padding: unset;
            margin: 0;
            border: 0;
            border-radius: 0;
            border-top: 1px solid #d0d0d0;
            box-shadow: none;
            border-bottom: none;

            &:first-child {
                border-top: none;
            }
            .product-tile-image {
    
                .product-tile-component-image {
                    max-width: 120px;
                    max-height: 120px;
                    object-fit: contain;
    
                }
            }
        }

        .product-tile-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: auto!important;
            gap: 12px;
        }

        .qtdContainer.productCard {
            width: 100%;

            input {
                left: -10px;
                font-size: 11px !important;

                &:not(:focus) {
                    &[value]:after {
                        content: 'un';
                        position: absolute;
                        right: 10px;
                    }
                }
            }

            .quantity-form-tile {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border: 1px solid #989898;
                .quantity {
                    border: none;
                    outline: none;
                    text-align: center;
                    width: 100%;
                    position: relative;
                    left: -5px;
                    top: 0px;
                    font-size: 13px;
                    font-weight: 600;
                }
            }
            button {
                width: 100%;
                max-width: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 32px;
                font-weight: 200;
                font-size: 20px;

                &.increaseAmountCart {
                    background: #0ed178;
                    color: white;
                    border-radius: 0px 4px 4px 0px;
                    &:hover {
                        background: #0DC16F;
                    }
                }
                &.decreaseAmountCart {
                    border-radius: 4px 0px 0px 4px;
                    color: #989898;
                    margin-top: -2px;
                }
            }
        }

        .quickBuyBtn {
            width: fit-content;
            height: 35px;
            padding: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #0ed178;
            border-radius: 5px;
            background: #0ed178;
            color: #fff;
            text-align: center;
            line-height: normal;
            white-space: nowrap;
        }

        button.quickBuyBtn.buttonUnavaliableModal {
            background: #23346C;
            border-color: #0f1b42;
            color: #FFF !important;
            font-size: 11px !important;
            padding: 15px 13px!important;
            width: fit-content;
            white-space: normal;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            line-height: 1.45;
        }

        .product-name-link {
            font-size: 12px;
        }

        .line-item-total-text {
            font-weight: 700;
        }

        .product-price, .precoProduto {
            font-size: 14px!important;
        }
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background: #f1f3f5;
        border-left: 1px solid darken(#f1f3f5, 10%);
    }

    ::-webkit-scrollbar-thumb {
        background: $brandColorDarker3;
    }

    .unit {
        display: block;
        font-size: 13px;
        position: relative;
        left: -7px;
        top: 6px;
        font-weight: 600;
    }

    .btn-wrapper {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    @media(max-width: 768px) {
        .product-tile-pd {
            width: 100%;
        }

        .product-tile-pd .productTileBox .product-tile-body .product-name-link {
            margin-bottom: 0 !important;
        }

        .product-tile-pd .quantityAndShop {
            margin-top: 0 !important;
        }
    }
}


.repeatOrder-container {
    right: -16px !important;
    left: unset !important;
}

#repeatOrder {
    font-family: Open Sans!important;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: #000!important;
    cursor: pointer;
}

.repeatOrder_button {
    width: 100%;
    border-radius: 5px;
    background: #00744A;
    color: $white;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.14px;
    border: 0;
    &:hover {
        opacity: 0.9;
    }
}



@media (max-width: 991px) {
    .brand {
        h1{
            max-height: 43px;
            font-size: 21px;
        }
        img {
            width: auto;
            height: 38px;
        }
    }
    .navbar-header {
        .user {
            .myAccountHeader-container {
                translate: -41%;
            }
        }
    }
    .mainHeader {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .navbar-header .user .myAccountHeader-container:has(.myAccountHeader-unlogged){
        translate: none;
        .myAccountHeader-unlogged {
            translate: none;
            position: fixed;
            right: 0;
            left: 0;
            top: 20%;
            margin: 0 auto;
            width: 100%;
            max-width: 315px;
            z-index: 1;
    
        }

        .login-modal-background {
            background-color: #000;
            height: 100%;
            position: fixed;
            opacity: 0.5;
            width: 100%;
            top: 0;
            left: 0;
        }
    }   
}

@media (max-width: 543px) {
    .search-mobile {
        padding-bottom: 0.5rem;
        padding-top: 0.25rem;
    }
}

@media (max-width: 430px) {
    .header {
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        gap: 0.3rem;
    }

    .header-whatsapp a {
        gap: 2px;
    }

    .mylistsHeader {
        span {
            display: none;
        }
    }

    .brand {
        width: 7rem !important;

        h1{
            max-height: 42px;
        }
    }

    .navbar-header {
        gap: 1px;

        .myList {
            height: auto;

            img {
                width: 21px;
            }
        }
    }
}

@media only screen {
    .category-menu{
        background: #EAEAEA;
        display: none;

        .menu-category-group{
            width: 100%;
            white-space: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
        // -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }

            ul {
                margin: 0 0 0 .5rem;
                padding: 0px;

                li {
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 500;
                    padding: .75rem;

                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .category-show{
        display: block;
    }
}
@media (max-width: 1000px) {
    img.minicart-img {
        width: 22px;
    }
}
