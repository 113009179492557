$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-copy: "\e936";
$icon-building: "\e900";
$icon-fast-shipping: "\e901";
$icon-quotation-list: "\e902";
$icon-shipping: "\e903";
$icon-tag: "\e904";
$icon-info: "\e905";
$icon-check-circle: "\e906";
$icon-filter-circle: "\e907";
$icon-menu-options: "\e908";
$icon-sort-alpha-asc: "\e909";
$icon-sort-alpha-desc: "\e90a";
$icon-sort-down: "\e90b";
$icon-share: "\e90c";
$icon-email: "\e90d";
$icon-link: "\e90e";
$icon-cart: "\e90f";
$icon-hamburger-menu: "\e910";
$icon-chevron-down: "\e911";
$icon-chevron-left: "\e912";
$icon-chevron-right: "\e913";
$icon-chevron-up: "\e914";
$icon-file-pdf: "\e915";
$icon-file-xml: "\e916";
$icon-rate: "\e917";
$icon-seller: "\e918";
$icon-my-location: "\e919";
$icon-search: "\e91a";
$icon-error_outline: "\e91b";
$icon-face-frown: "\e91c";
$icon-face-smile: "\e91d";
$icon-favorite_border: "\e91e";
$icon-favorite: "\e91f";
$icon-list-include: "\e920";
$icon-star: "\e921";
$icon-visibility_off: "\e922";
$icon-visibility: "\e923";
$icon-more: "\e924";
$icon-info-circle-fill: "\e925";
$icon-boleto: "\e926";
$icon-clock: "\e927";
$icon-credit-card: "\e928";
$icon-pix: "\e929";
$icon-change: "\e92a";
$icon-check: "\e92b";
$icon-close: "\e92c";
$icon-facebookFilled: "\e92d";
$icon-youtube: "\e92e";
$icon-instagram: "\e92f";
$icon-facebook: "\e930";
$icon-confirmed-green: "\e931";
$icon-delivered-gray: "\e932";
$icon-map-location: "\e933";
$icon-separation-gray: "\e938";
$icon-best-reviews: "\e940";
$icon-delivery-parcel: "\e941";
$icon-money-bag: "\e947";
$icon-trash: "\e9f8";
$icon-remove-coupom: "\e9fa";
$icon-footer-logo: "\ea0e";
$icon-profile: "\ea20";
$icon-filter: "\ea21";
$icon-whatsapp: "\ea22";