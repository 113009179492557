// TrackOrder Modal and TrackOrder page
#trackOrderModal, #cancelOrderModal {
    background: rgba(0, 0, 0, 0.5);
    display: none;

    .content-button-edit {
        span {
            text-decoration: underline;
            font-size: 14px;
        }
    }

    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
        background: #dbd7d7;
        border-color: #b9b7b7;
        color: black;
        opacity: 0.32;
    }

    .col-10.myAddressesModal {
        p {
            color: #000;
            font-size: 12px;
            font-weight: 400;
            margin: 0px;
        }
    }

    .modal-shipping {
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        border-radius: 5px;
        height: 100dvh;
        pointer-events: all;
        margin: auto;
        padding: 1rem;

        @media (min-width: 992px) {
            max-width: 600px;
        }

        .modal-content {
            pointer-events: all;
            margin: auto;
            padding: 0;

            @media (min-width: 992px) {
                min-width: 600px;
            }

            .close {
                margin: 12px 12px 0 0;
            }

            @media (min-width: 992px) {
                min-width: unset;

                .close {
                    margin: 24px 24px 0 0;
                }

                .img-modal {
                    margin-top: -80px;
                }
            }


            .modal-header {
                margin-top: -10px;
            }

            .form-input-cep {
                margin: 0 !important;
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .btn-outline-primary {
                &:hover {
                    border-color: #0ed178;
                    color: #0ed178;
                }
            }

            .form-group {
                margin: 0;
            }
        }
    }

    .modal-header {
        position: relative;
        border: none;
        padding: 0 2rem;

        .modal-localization {
            color: #000;
            font-size: 13px;
            font-weight: 600;
            text-decoration-line: underline;
            border-color: transparent;
            background: #FFF;
        }

        .title-container {
            align-items: flex-end;
            justify-content: end;
            flex-wrap: wrap-reverse;
            gap: 0 2rem;

            .modal-title {
                flex: 1 1 auto;
            }

            .close-button {
                all: unset;
                cursor: pointer;
                width: 2rem;
                height: 2rem;

                img {
                    width: 100%;
                }
            }
        }
    }

    .form-row {
        margin-right: 0px;
        margin-left: 0px;
    }

    .locationIcon {
        margin-right: 10px;
        margin-left: 10px;
    }

    .locationAddress {
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-wrap: wrap;
    }

    .modal-body {
        padding-top: 5px !important;
        padding-bottom: 5px !important;

        hr {
            margin: 1.5rem 0;
        }
    }

    .card-body {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .input-content-cep {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .label-form {
        color: #1f1f1f;
        font-size: 14px;
        font-weight: 300;
    }

    p.textCep {
        margin-top: 5px;
        color: #002b70;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
    }

    .input-form {
        height: 51px;
        border-radius: 5px;
        border: 0.5px solid #999;
        background: #fff;

        &:focus {
            border: 1px solid #3d3d3d;
        }
    }

    p.textCep {
        margin-top: 5px;
        color: #002b70;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
    }

    #inputStateModal {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    #inputNumberModal {
        text-align: center;
    }

    .modal-title {
        color: #002b70;
        line-height: normal;
        text-align: left;
    }

    .modal-subtitle {
        color: #002b70;
        line-height: normal;
        font-style: normal;
        margin-bottom: 0px !important;
    }

    .modal-location {
        margin-top: 30px;
        display: flex;
        flex-wrap: nowrap;

        p {
            font-size: 13px;
            text-decoration: underline;
            margin: 4px 0px 0px 4px;
        }
    }

    .btn-outline-primary {
        height: 51px;
        border-radius: 5px;
        background: #fff;
        color: #00744A;
        font-size: 15px;
        width: 100%;
        border: 2px solid #00744A;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        &:hover {
            color: #00744A;
            background: #fff;
            text-decoration: none;
        }
    }

    .modal-button {
        height: 51px;
        border-radius: 5px;
        background: #00744A;
        color: #fff;
        font-size: 15px;
        width: 100%;
        padding-top: 12px;

        &:hover {
            color: #fff;
            background: #00744A;
            text-decoration: none;
        }
    }


    .txt-modal {
        color: #002b70;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
        margin-bottom: 30px;
    }


    .title-standard,
    .title-standard-edit {
        color: #23346c;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 10px;
    }

    @media (max-width:768px) {

        #trackOrderModal .modal-shipping {
            width: 90%;
            padding: 0px !important;

            .modal-content {
                // width: 100%;
                // height: 100%;
                // max-width: inherit;

            }
        }

    }
    
    .modal-cancel-order{
        min-width: 600px;
        .modalCancelOrderContent{
            .modal-header{
                margin-bottom:24px
            }
            .modal-body {
                p {
                    font-size: 16px;
                    margin-bottom: 16px;
                }
                span {
                    font-size: 14px;
                }
                //resposta da requisição de cancelamento da order
                .modal-response-cancel{
                    p {
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                    &.response-success{
                        color: #02754B;
                        span {
                            color: #000;
                        }
                    }
                    &.response-error{
                        color: #CC0000;
                    }
                }
                .modal-instructions-cancel{
                    p {
                        font-size: 14px;
                        margin-bottom: 25px;
                    }
                    a {
                        color: #02754B;
                        font-weight: 600;
                    }
                }
            }
            .modal-button {
                padding: 6px !important;
            }
        }
    }
}

button {
    &.collapse-toggle {
        font-size: 13px;
    }
}

.container-details, .container-header {
    padding: 12px 24px !important;
    gap: 20px;
    small {
        min-width: 80px;
        &.small {
            min-width: 40px;
        }
        &.large {
            min-width: 100px;
        }
    }
}


.trackOrderDetails {
    color: #454545 !important;

    button {
        a {
            color: white !important;
        }

        &:disabled {
            pointer-events: none;
        }
    }

    a {
        color: #454545 !important;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .cancelOrder_button {
        width: 100%;
        border-radius: 5px;
        border: 1px solid $black;
        color: $black;
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.14px;
        &:hover {
            opacity: 0.9;
        }
        &:disabled {
            opacity: 0.4;
        }
    }

    .editDatas-container.account-page__container {
        padding: 40px;
        // tablet and mobile
        @media(max-width:'992px') {
            padding: 0;
            margin: 0;
            background: transparent;
            border: none;
        }
        .trackOrderDetails__content {
            background:#F6F6F6;
            // tablet and mobile
            @media(max-width:'992px') {
                background: transparent;
                margin: 0;
                padding: 0;
            }
        }
    }


    .orderDetails__content {
        display: flex;
        flex-direction: column;
        grid-row-gap: 24px;

        .dataOrder-header {
            flex-direction: row;
            // tablet and mobile
            @media(max-width:'768px') {
                flex-direction: column;
            }
            .buyAgain-container {
                // tablet and mobile
                @media(max-width:'992px') {
                    margin-top: 12px;
                }
            }
            .dataOrder-header__info {
                flex-direction: row;
                // tablet and mobile
                @media(max-width:'768px') {
                    flex-direction: column;
                }
                column-gap: 40px;
            }
        }

        .dataOrder-container {
            gap: 24px;
            flex-direction: row;
            // tablet and mobile
            @media(max-width:'992px') {
                flex-direction: column;
            }

            .dataOrder-container__item {
                width: 100%;
                padding: 24px;
                border-radius: 6px;
                background-color: white;

                button {
                    background-color: #0ED178;
                    border: none;
                }

                h3 {
                    font-size: 14px;
                }

                .start-lines {
                    display: flex;

                    &::before {
                        content: "";
                    }
                }

                .qrcode__content {
                    display: flex;
                    img {
                        max-width: 100px;
                    }

                    h3 {
                        font-size: 24px;
                    }
                    .container-popUpCopy{
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        bottom: -40px;
                        right: 0;
                        .popover-popUpCopy{
                            background: $bgLighter3;
                            position: absolute;
                            width: 25px;
                            z-index: 0;
                            transform: rotate(45deg);
                            height: 25px;
                        }
                        span {
                            background: $bgLighter3;
                            font-size: 11px;
                            margin-top: 5px;
                            padding: 5px;
                            text-transform: uppercase;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        .dataShipping-header {
            .dataShipping-header__info {
                i {
                    font-size: 24px;
                }

                h3 {
                    font-size: 14px;
                }

                .btn-message-seller{
                    border: 0px;
                    background: none;

                    color: #747474;
                    font-size: 12px;
                    font-weight: 600;
                    text-decoration-line: underline;
                    margin-left: 8px;

                    &:focus {
                        box-shadow: none !important;
                    }
                }
            }
        }

        .dataShipping-details {
            gap: 24px;
            flex-direction: row;
            // tablet and mobile
            @media(max-width:'992px') {
                flex-direction: column;
            }

            .shipping_nf {
                h3 {
                    font-size: 12px
                }

                a {
                    font-size: 14px;
                }
            }

            .dataShipping-details__item {
                background: #fff;
                padding: 24px;
                border-radius: 6px;
                width: 70%;
                // tablet and mobile
                @media(max-width:'992px') {
                    width: 100%;
                }
                height: fit-content;

                h3 {
                    font-size: 14px;
                }

                &.order-flow {
                    padding: 24px 16px;
                    width: 34%;
                    // tablet and mobile
                    @media(max-width:'992px') {
                        width: 100%;
                    }
                }

                .timeline {
                    position: relative;
                    padding: 0;

                    li {
                        display: flex;

                        .timeline--description {
                            padding-bottom: 24px;
                            color: #AFAFAF;
                        }

                        &:last-child{
                            .timeline--circle{
                                 &:before {
                                    height: 0 !important;
                                }
                            }
                        }

                        .timeline--circle {
                            flex: 0 0 50px;
                            position: relative;
                            text-align: center;

                            // vertical line
                            &.exist:before {
                                width: 2px;
                                background: #02754B;
                            }

                            // vertical line
                            &:before {
                                content: '';
                                position: absolute;
                                height: 100%;
                                width: 1px;
                                background: #AFAFAF;
                                margin: auto;
                                right: 0;
                                left: 0;
                            }

                            i {
                                position: relative;
                                display: inline-block;
                                background: #AFAFAF;
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                            }
                        }

                        &.exist {
                            // vertical line
                            .timeline--circle {
                                &:before {
                                    width: 2px;
                                    background: #02754B !important;
                                }
                                i {
                                    background: #02754B !important;
                                }
                            }
                            .timeline--description {
                                small {
                                    color: #454545;
                                }
                                span {
                                    color: #02754B;
                                    font-weight: bold;
                                    font-size: 16px;
                                }
                            }
                        }

                        &.selected {
                            .timeline--description {
                                small {
                                    color: #454545;
                                }
                                span {
                                    color: #02754B;
                                    font-weight: bold;
                                    font-size: 16px;
                                }
                            }

                            .timeline--date,
                            .timeline--circle {
                                &:before {
                                    margin-top: 15px;
                                }

                                &:after {
                                    content: "";
                                    background: transparent;
                                    box-sizing: border-box;
                                    width: 20px;
                                    height: 20px;
                                    border: 2px solid #02754B;
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                    border-radius: 50%;
                                    z-index: 1;
                                }
                            }

                            i {
                                background: transparent !important;
                                width: 20px !important;
                                height: 20px !important;

                                &:after {
                                    content: '';
                                    background: #02754B;
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                    display: inline-block;
                                    position: absolute;
                                    margin: auto;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }

                &.order-product-summary {
                    .dataProduct-container {
                        border-bottom: 1px solid #DBDBDB;
                        &:last-child {
                            border: none;
                        }

                        .details__item-image {
                            // tablet and mobile
                            @media(max-width:'768px') {
                                padding: 0;
                                img {
                                    width: 50px !important;
                                }
                            }
                            .details-product-container  {
                                // tablet and mobile
                                @media(max-width:'768px') {
                                    p {
                                        font-size: 12px;
                                    }
                                    small {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}