.widget-whatsapp {
    position: fixed;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #00744a;
    border-radius:100%;
    height: 60px;
    width: 60px;
    bottom: 3%;
    right: 1%;
    z-index: 1;
  a {
    color: #FFF;
    text-decoration:none;
    font-size: 34px;
  }
  @media (max-width:992px) {
    right: 3%;
  }
}