@import "../../../../../../app_storefront_base/cartridge/client/default/scss/components/toastMessage";

.attribute {
    margin-top: 0.938em;

    label {
        display: block;
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {

    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }
}

.prices-add-to-cart-actions {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 50;
    padding-right: 0;
    padding-left: 0;
    left: 0;

    @include media-breakpoint-down(xs) {
        background-color: rgba(255, 255, 255, 0.95);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }

    .price {
        text-align: center;
    }
}

.prices {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    text-align: initial !important;
}

.prices-sm-md {
    padding-top: 1em;
    text-align: initial !important;

    .product-name-sm-md {
        display: none;
    }

    p {
        margin: 0;
        font-size: 12px;
    }
}

.flex-sm-md {
    .line-item-price-info {
        color: #696969;
        margin-right: 1em;
    }

    .price {
        .sales {
            .value {
                font-size: 17px;
            }
        }
    }
}

@media only screen and (max-width: 1280px) and (min-width: 768px) {
    .prices-sm-md {
        .product-name-sm-md {
            display: initial;
        }
    }

    .flex-sm-md {
        display: flex;
        align-items: center;
    }
}

.cart-and-ipay {
    text-align: center;

    @include media-breakpoint-only(xs) {
        padding-bottom: 0;

        .btn {
            width: 98%;
            margin: 1%;
            display: block;
        }
    }
}

.add-to-cart-messages {
    @include toast-message();
}

.add-to-basket-alert {
    @include toast-alert();
}

.simple-quantity {
    margin-top: 1em;
}

.main-attributes {
    margin-top: 1em;
}

.size-chart {
    margin-top: 1.071em;
}

div.availability {
    margin-top: 1.071em;
}

.bundle-item {
    padding-bottom: 1em;
    border-bottom: 1px solid $hr-border-color;

    &:last-child {
        border-bottom: none;
    }
}

.container.product-detail {
    margin-top: 2em;
    margin-bottom: 2em;

    @include media-breakpoint-only(xs) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.product-option:not(:first-child) {
    margin-top: 1.071em;
}


.product-option:not(:first-child) {
    margin-top: 1.071em;
}

.pdpContainer {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 22px;
    margin-top: 16px;
    grid-template-areas:
        "carousel preco"
        "descricao .";
}

.descAndDetails {
    color: #010101;
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.182%;
    margin: 0;
}

p#collapsible-details-1.value.content {
    margin: 0;
}

.descDetailBox {
    .title {
        margin-bottom: 18px;
        color: #23346C;
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        padding-left: 0
    }

    padding: 36px 54px 36px 44px;
    border-radius: 5px;
    border: 0.5px solid #D0D0D0;
    background: #FFF;
    max-width: 793px;
}

.detailsBox {
    .title {
        margin-bottom: 18px;
        color: #23346C;
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        width: 20em;
    }

    padding: 26px 0px 26px 44px;
    border-radius: 5px;
    border: 0.5px solid #D0D0D0;
    background: #FFF;
    max-width: 793px;
}

.description-and-detail {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 0 !important;
}

.product-name {
    color: #000;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.product-detail.product-wrapper {
    border-radius: 5px;
    border: 0.5px solid #D0D0D0;
    background: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 29px 45px 50px 45px;
    position: relative;

    .favIconAndDiscount {
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        z-index: 2;

        .add-product-to-product-list,
        .add-to-productlist-login-btn{
            border: none;
            background: none;
            img {
                width: 32px;
            }
        }
    }
}


.add-product-to-product-list,
.add-to-productlist-login-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    background: none;
    font-size: 11px;
    text-decoration: none;
    color: #000 !important;
    text-decoration: none;
}
.icon-pdp-font{
    font-size: 30px;
}

.cart-and-ipay {
    margin-top: 1em;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: center;

    .add-to-cart {
        border-radius: 5px;
        background: #0ED178;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 100%;
        max-width: 390px;
        padding: 16px 10px;
        border: none;
    }
}

.prices-add-to-cart-actions {
    padding: 0;
}

.description {
    margin-bottom: 0 !important;
}

.qttSelector {
    border-radius: 5px;
    border: 0.5px solid #000;
    background: #FFF;
    max-width: 101px;
    padding: 1px;

    .plus-btn,
    .minus-btn,
    .quantity-select.custom-select.form-control {
        background: white;
        border: none;
        outline: none;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center !important;
        color: #000;
        height: 41px;
    }

    .quantity-select.custom-select.form-control {
        padding: 0 !important;
    }
}

.attribute,
.quantity-input {
    display: flex;
    align-items: center;
}

.attribute {

    .qttStyle {
        border: 1px solid black;
        border-radius: 5px;
        font-weight: 600;
        color: #000;

        ::after {
            content: url(../images/arrowDownIcon.svg);
            ;
        }
    }

    .descriptionAttr {
        color: #000;
        font-size: 11px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .form-control:focus {
        border: 1px solid black;
        box-shadow: none;
    }

}

.attributes {
    padding: 0 !important;

    .itemSize {
        margin-right: 55px;
    }

    .colorAttr {
        margin-right: 18px;
    }

    .qttLabel {
        margin-bottom: 0;
        margin-right: 38px
    }
}

.sizeOption {
    border-radius: 5px;
    border: 0.5px solid #989898;
    background: #FFF;
    min-width: 127px;
    height: 41px;
    margin-right: 10px;

    color: #989898;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    :focus {
        border-radius: 5px;
        border: 1px solid #23346C;
        background: #FFF;

        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.product-code__container {
    column-gap: 5px;
    .product-id,
    .product-code {
        color: #696969;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.attribute.quantity {
    margin-bottom: 22px;
}

@media (max-width:835px) {

    .descDetailBox {
        .title {
            margin-bottom: 35px;
        }
    }

    .attribute {
        .color-attribute {
            border: none;
            background: none;
            padding: 0;
        }
    }

    .prices-add-to-cart {
        .product-price {
            display: none;
        }

        .line-item-price-info {
            margin: 0px;
        }

    }

    .detailsBox {
        padding: 0;
    }

    .pdpContainer {
        grid-template-areas:
            "carrosel"
            "preco"
            "descricao";
    }

    .pdpContainer {
        grid-template-columns: 1.22fr 1fr;
    }

    .product-detail.product-wrapper {
        padding: 25px;

        .favIconAndDiscount {
            margin-bottom: 1px;
        }
    }
}

@media(max-width:630px) {
    .product-detail.product-wrapper {
        padding: 32px;
    }
}

@media (max-width: 991px) {
    .attribute {
        .qttStyle {
            font-size: 12px;
        }
    }

    .product-name {
        font-size: 18px;
    }
}

@media (max-width: 720px) {
    .product-name {
        font-size: 14px;
    }

    .product-id {
        margin-bottom: 0;
    }
}


.pricesAndDiscountBox {
    display: flex;
    align-items: flex-end;
    color: white;
    gap: 12px;
}


.product-unavailable-wrapper {
    background-color: #f7f7f7;
    border-radius: 5px;
    margin: 2px;
}
.product-unavailable-msg {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    line-height: normal;
    align-items: center;
    white-space: nowrap;
    
}
.unavailable-button-wrapper {
    display: flex;
    justify-content: center;
}
.button {
    padding: 8px;
    background: #23346C;
    color: #FFF !important;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    text-decoration: none;
}
.form-unavailable {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    padding: 0.5px;
}
.unavailable-input {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    height: 48px;
    width: 100%;
    margin: 8px 0px;
    color: #7F858D;
    padding-left: 16px;
    border: 1px solid #010101;
    border-radius: 4px;
}
div#unavailable-modal {
    z-index: 99999999;
}


.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
}

@media (max-width: 425px) {
    .pricesAndDiscountBox {
        gap: 24px;
    }
}
