@import "../variables";

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.price {
    color: $grey7;

    .price-content-tile {
        display: flex;
        flex-direction: column;
    }

    .strike-through {
        text-decoration: line-through;
        color: #808080;
        font-size: 16px;
        font-weight: 500;
    }

    .sales {
        font-size: 25px;
    }

    .starting,
    .range,
    .sales {
        font-weight: bold;
    }

    .tiered {
        color: $grey7;

        table {
            border-top: 1px solid $grey3;
            margin: 0 auto;

            tr {
                &:nth-child(odd) {
                    background-color: $grey2;
                }
            }
        }

        td,
        span.price {
            font-size: 0.875rem;
            font-weight: bold;
        }

        td {
            padding: 0.313rem;

            &.quantity {
                font-weight: normal;
                text-align: right;
            }

            &.value {
                text-align: left;
            }
        }

        .table-header {
            font-size: 1.125rem;
            padding: 0.313rem;
        }

        .column-header {
            font-size: 1rem;
            padding: 0.313rem;
            font-weight: normal;
        }

        .column-header.quantity {
            text-align: right;
        }
    }
}